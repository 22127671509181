<script>
	import Toast from './Toast.svelte';
	import { Version } from '$lib/store.js';

	let recentUpdates =
		$Version.app == 'omnipilot'
			? [
					{
						date: 'Nov 15th, 2023',
						version: '0.0.109',
						text: ['First release of full Omnipilot app!']
					}
			  ]
			: [
					{
						date: 'Oct 16th, 2023',
						version: '0.2.15',
						text: [
							'Rewrite of categorisation code to make categories more editable',
							'Fully custom categories',
							'Help available by email and iMessage'
						]
					},
					{
						date: 'Sep 24th, 2023',
						version: '0.2.14',
						text: ['Possible fix to session coach not showing for some users']
					},
					{
						date: 'Sep 4th, 2023',
						version: '0.2.13',
						text: ['A couple more fixes']
					},
					{
						date: 'Aug 29th, 2023',
						version: '0.2.12',
						text: ['Bugfixes for new tracking system']
					},
					{
						date: 'Aug 14th, 2023',
						version: '0.2.11',
						text: ['Big performance update, fixing hanging issues for some users']
					},
					{
						date: 'Jul 10th, 2023',
						version: '0.2.7-10',
						text: ['Tracking bugfix']
					},
					{
						date: 'Jun 22th, 2023',
						version: '0.2.6',
						text: [
							'Menubar widget now updates even if app is not opened',
							'Potential tracking bugfix for a small number of new users on macOS 11',
							'Still coming soon.. rule-viewer and editor for categories'
						]
					},
					{
						date: 'Jun 16th, 2023',
						version: '0.2.5',
						text: ['Efficiency improvements in data fetching.']
					},
					{
						date: 'Jun 12th, 2023',
						version: '0.2.3',
						text: ['Tracking reliability improvements.']
					},
					{
						date: 'Jun 11th, 2023',
						version: '0.2.2',
						text: [
							'Big categorization fixes - recategorizations should stick better.',
							'Improved AI categorization accuracy.'
						]
					},
					{
						date: 'Jun 8th, 2023',
						version: '0.2.1',
						text: ['Fix for session coach! Sorry I broke it.', 'Arc browser URL support']
					},
					{
						date: 'Jun 6th, 2023',
						version: '0.2.0',
						text: [
							'SigmaOS and Firefox URL support!',
							'For Firefox: go to about:config, accept the warning, and set accessibility.force_disabled to -1, and Magicflow will start picking up URLs!',
							'Potential bugfixes to occasional crashes for some users'
						]
					},
					{
						date: 'Jun 5th, 2023',
						version: '0.1.98/9',
						text: ['Bugfixes for users using pm/am time']
					},
					{
						date: 'May 30th, 2023',
						version: '0.1.97',
						text: [
							'Hey everyone! Lots of new users here, welcome to Magicflow, would love any feedback you have, any time!',
							'Launch fixes! (reset password)'
						]
					},
					{
						date: 'May 22th, 2023',
						version: '0.1.96',
						text: ['Onboarding updates']
					},
					{
						date: 'Apr 24th, 2023',
						version: '0.1.95',
						text: ['Bugfix to tracking.']
					},
					{
						date: 'Apr 23th, 2023',
						version: '0.1.94',
						text: [
							'Faster updating with new data',
							'Fix to some new users not seeing data',
							'Context detection fix'
						]
					},
					{
						date: 'Apr 13th, 2023',
						version: '0.1.93',
						text: ['Meeting and watching detection is more reliable']
					},
					{
						date: 'Apr 12th, 2023',
						version: '0.1.91/2',
						text: ['Bugfixes and performance updates']
					},
					{
						date: 'Mar 21st, 2023',
						version: '0.1.90',
						text: ['New AI session coaching!']
					},
					{
						date: 'Mar 1st, 2023',
						version: '0.1.89',
						text: ['Fix to some not seeing event data.']
					},
					{
						date: 'Feb 22nd, 2023',
						version: '0.1.88',
						text: [
							'Fix to users occasionally being logged out when they lose internet',
							'Minor fixes and extra logging for some users.'
						]
					},
					{
						date: 'Feb 16th, 2023',
						version: '0.1.86',
						text: [
							'UI updating with latest data works more reliably',
							'Fix for some new users local database not being created properly'
						]
					},
					{
						date: 'Feb 7th, 2023',
						version: '0.1.85',
						text: ['Reliability improvements and bugfixes']
					},
					{
						date: 'Jan 26th, 2023',
						version: '0.1.84',
						text: ['Huge performance improvement and fix']
					},
					{
						date: 'Jan 19th, 2023',
						version: '0.1.83',
						text: [
							'Social tab now shows leaderboard for every group',
							'Onboarding improvements, web subscription flow',
							'Bug/performance fixes. More on the way!'
						]
					},
					{
						date: 'Jan 12th, 2023',
						version: '0.1.82',
						text: [
							'Rewrote data flow to be more reliable so UI should be more consistent and faster to update'
						]
					},
					{
						date: 'Jan 12th, 2023',
						version: '0.1.81',
						text: [
							"Huge new UI update! Much simpler and clearer experience. We'd love your feedback!"
						]
					},
					{
						date: 'Dec 6th, 2022',
						version: '0.1.79/80',
						text: ['Bugfixes for international users']
					},
					{
						date: 'Dec 6th, 2022',
						version: '0.1.78',
						text: [
							'Huge performance improvements (if curious, we now do incremental data updates in the UI rather than full refreshes).',
							'If performance is still an issue for you at all, please let me know ASAP by clicking the blue button! Thanks.'
						]
					},
					{
						date: 'Dec 3rd, 2022',
						version: '0.1.77',
						text: ['Cross-device syncing of productivity!']
					},
					{
						date: 'Nov 25nd, 2022',
						version: '0.1.76',
						text: ['Bugfix']
					},
					{
						date: 'Nov 25nd, 2022',
						version: '0.1.75',
						text: ['New session feedback', 'New web login flow.', 'New in-app onboarding']
					},
					{
						date: 'Nov 21st, 2022',
						version: '0.1.74',
						text: ['Improved homepage UI.', 'Fix to pomodoro timer.']
					},
					{
						date: 'Nov 15th, 2022',
						version: '0.1.73',
						text: ['Bugfixes']
					},
					{
						date: 'Nov 15th, 2022',
						version: '0.1.72',
						text: ['Bugfixes']
					},
					{
						date: 'Nov 15th, 2022',
						version: '0.1.71',
						text: ['UI saturation toned down a little!']
					},
					{
						date: 'Nov 9th, 2022',
						version: '0.1.70',
						text: ['Less intrusive update notifications', 'Onboarding improvements']
					},
					{
						date: 'Nov 7th, 2022',
						version: '0.1.69',
						text: ['Bugfix']
					},
					{
						date: 'Nov 6th, 2022',
						version: '0.1.68',
						text: ['Better handling of timezone changes', 'Bugfix for new users']
					},
					{
						date: 'Nov 4th, 2022',
						version: '0.1.67',
						text: ['Onboarding and performance improvements']
					},
					{
						date: 'Oct 31st, 2022',
						version: '0.1.66',
						text: [
							'Added Google Calendar integration! (beta)',
							'Message us for API access for developers'
						]
					},
					{
						date: 'Oct 24th, 2022',
						version: '0.1.65',
						text: [
							'Fixes white screen after user subscribes',
							'No Data now only shows when no data for day'
						]
					},
					{
						date: 'Oct 23th, 2022',
						version: '0.1.64',
						text: ['Bugfix']
					},
					{
						date: 'Oct 22th, 2022',
						version: '0.1.63',
						text: ['Bugfix']
					},
					{
						date: 'Oct 21th, 2022',
						version: '0.1.62',
						text: ['Fixes to tracking starting', 'More reliable event tracking']
					},
					{
						date: 'Oct 14th, 2022',
						version: '0.1.61',
						text: ['Bugfix']
					},
					{
						date: 'Oct 14th, 2022',
						version: '0.1.60',
						text: [
							'Free plan released! Tell your friends!',
							"Won't open every time it updates in background",
							'custom categories viewable and updateable',
							'Social page revamped',
							'Onboarding updates'
						]
					},
					{
						date: 'Sep 22th, 2022',
						version: '0.1.59',
						text: [
							'Slick new recategorisation flow, just hover over an activity to see it!',
							'Bugfix for day changing'
						]
					},
					{
						date: 'Sep 19th, 2022',
						version: '0.1.58',
						text: [
							"Go back in time! Switch days in the home screen, or hover over the start of week in the 'Time' tab to go back in time and see how you spent past weeks.",
							'Bugfix for new users'
						]
					},
					{
						date: 'Sep 15th, 2022',
						version: '0.1.57',
						text: [
							"Wait a sec.. something's changed - huge new UI update, let us know what you think via iMessage!",
							'Tracking fix for a handful of users'
						]
					},
					{
						date: 'Sep 10th, 2022',
						version: '0.1.56',
						text: [
							'Background performance improvements preparing for big UI upgrade',
							'Potential bugfix'
						]
					},
					{
						date: 'Sep 6th, 2022',
						version: '0.1.55',
						text: ['Bugfix']
					},
					{
						date: 'Sep 2nd, 2022',
						version: '0.1.54',
						text: ['New homepage UI', 'Insights upgrade with relationship overview graph']
					},
					{
						date: 'Aug 15th, 2022',
						version: '0.1.53',
						text: ['Bugfix']
					},
					{
						date: 'Aug 7th, 2022',
						version: '0.1.52',
						text: ['Performance improvements']
					},
					{
						date: 'Jul 28th, 2022',
						version: '0.1.51',
						text: ['New insight: distribution of hours worked per day']
					},
					{
						date: 'Jul 25th, 2022',
						version: '0.1.50',
						text: [
							'New home-screen and flow-meter design',
							'Daily review page',
							'New insight: most focused categories'
						]
					},
					{
						date: 'Jul 13th, 2022',
						version: '0.1.49',
						text: ['Arc Browser support', 'Onboarding bugfix']
					},
					{
						date: 'Jul 7th, 2022',
						version: '0.1.47/48',
						text: ['Referral link more discoverable', 'Reliability']
					},
					{
						date: 'Jun 22th, 2022',
						version: '0.1.46',
						text: ['Tracking reliability fix']
					},
					{
						date: 'Jun 22th, 2022',
						version: '0.1.44/45',
						text: [
							'Fix to windowing code issues',
							'Better handling of sensitive data',
							'Fixed some app crashes',
							'More tracking reliability improvements'
						]
					},
					{
						date: 'Jun 21th, 2022',
						version: '0.1.43',
						text: [
							'Custom contexts are here! Define your own contexts that span across categories! Open the context-switches deep dive and you should see a button to edit yours.',
							'Error handling and retries for tracking errors.'
						]
					},
					{
						date: 'Jun 14th, 2022',
						version: '0.1.42',
						text: [
							'Can now leave social groups',
							'Click events to recategorize them',
							'Minor cleanup'
						]
					},
					{
						date: 'Jun 14th, 2022',
						version: '0.1.40/41',
						text: ['Rapid Fixes']
					},
					{
						date: 'Jun 14th, 2022',
						version: '0.1.39',
						text: ['Flow Meter', 'More onboarding updates', 'Product Hunt launch!! 🎉']
					},
					{
						date: 'Jun 10th, 2022',
						version: '0.1.38',
						text: ['Major reliability updates', 'New onboarding']
					},
					{
						date: 'Jun 3rd, 2022',
						version: '0.1.37',
						text: [
							'Ethi is now Magicflow (magicflow.com!!)',
							'Status bar & control panel!',
							'Privacy: keep URLs and Titles local-only (click status bar)',
							'Timeline view'
						]
					},
					{
						date: 'Jun 1st, 2022',
						version: '0.1.36',
						text: [
							"Sessions in the Session Length details screen can now be clicked to look at your session's details.",
							'Added some helpful logging for faster fixes in the future!',
							'Bugfixes for onboarding and some edge-cases'
						]
					},
					{
						date: 'May 28th, 2022',
						version: '0.1.35',
						text: ["Bugfix! We'll make sure future versions are better tested!"]
					},
					{
						date: 'May 27th, 2022',
						version: '0.1.34',
						text: ['New Onboarding tour!']
						// action: {
						// 	function: () => {
						// 		$UserInfo.toursCompleted = [];
						// 		$Onboarding = true;
						// 		showToast = false;
						// 	},
						// 	text: 'Try our new tour'
						// }
					},
					{
						date: 'May 25th, 2022',
						version: '0.1.33',
						text: [
							'New Home UI',
							'Tracking performance 10x better!',
							'Apple Health integration improvements',
							'App should quit and restart more reliably'
						]
					},
					{
						date: 'May 20th, 2022',
						version: '0.1.32',
						text: ['Fix to onboarding bugs!']
					},
					{
						date: 'May 18th, 2022',
						version: '0.1.31',
						text: [
							'Added changelog!',
							'Fixed screentime graph bug.',
							'More performance improvements',
							'Made leaderboard updates more reliable',
							'Weird white-spaces fixed'
						]
					},
					{
						date: 'May 17th, 2022',
						version: '0.1.30',
						text: [
							'Major performance improvements',
							'Anonymised incognito mode.',
							'Fixed weekly report screenshots',
							'Fixed app-reopening bugs',
							'Fixed most pop-up errors.'
						]
					}
			  ];
	let showToast = true;
</script>

<Toast css={'max-height:60vh;overflow-y:scroll;'} bind:showToast>
	<p class="font-semibold">What we've been working on</p>
	<ol class="relative border-l border-gray-200 dark:border-gray-700">
		{#each recentUpdates as update, i}
			<li class="mb-10 ml-6">
				<span
					class="flex absolute -left-3 justify-center items-center w-6 h-6 bg-blue-200 rounded-full ring-8 ring-white dark:ring-gray-900 dark:bg-blue-900"
				>
					<svg
						class="w-3 h-3 text-blue-600 dark:text-blue-400"
						fill="currentColor"
						viewBox="0 0 20 20"
						xmlns="http://www.w3.org/2000/svg"
						><path
							fill-rule="evenodd"
							d="M6 2a1 1 0 00-1 1v1H4a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2h-1V3a1 1 0 10-2 0v1H7V3a1 1 0 00-1-1zm0 5a1 1 0 000 2h8a1 1 0 100-2H6z"
							clip-rule="evenodd"
						/></svg
					>
				</span>
				<h3 class="flex items-center mb-1 text-lg font-semibold text-gray-900 dark:text-white">
					Magicflow {update.version}
					{#if !i}
						<span
							class="bg-blue-100 text-blue-800 text-sm font-medium mr-2 px-2.5 py-0.5 rounded dark:bg-blue-200 dark:text-blue-800 ml-3"
							>Latest</span
						>
					{/if}
				</h3>
				<time class="block mb-2 text-sm font-normal leading-none text-gray-400 dark:text-gray-300"
					>Released on {update.date}</time
				>
				{#each update.text as text}
					<p class="mb-0 text-sm font-normal text-gray-500 dark:text-gray-200">
						• {text}
					</p>
				{/each}
				{#if update.action}
					<button class="mt-2 bg-white text-black" on:click={update.action.function}
						>{update.action.text}</button
					>
				{/if}
			</li>
		{/each}
	</ol>
</Toast>
