<script>
	import FeedbackButton from './FeedbackButton.svelte';
	import { goto } from '$app/navigation';
	import { Version } from '$lib/store';
	export let page = '/';

	let help = {};

	$: contextualHelp = help[page];
</script>

<!-- <iframe title="chat zone" src="https://area.cx/idgJTAotp634tsFk4a7vrS" class="w-full h-full" /> -->

<h2 class="text-center">Need help?</h2>
<p>
	Message Mike using the blue chat icon in the top right corner, email us at hello@{$Version.app ===
	'omnipilot'
		? 'omnipilot.ai'
		: 'magicflow.com'}, or DM us at
	<a href="https://twitter.com/magicflowHQ" target="_blank">@magicflowHQ</a>
	- we'd love to chat!
</p>
{#if contextualHelp}
	<h1>{contextualHelp.title}</h1>
	<p>{contextualHelp.description}</p>
	<iframe
		style="aspect-ratio:1.8"
		src={'https://www.youtube.com/embed/' + contextualHelp.videoID}
		title="Help video on Youtube"
		frameborder="0"
		allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope;
    picture-in-picture"
		allowfullscreen
	/>
{:else}
	<div>
		<FeedbackButton feedbackQ={"How can we help? We'll get back to you by email ASAP!"} />
		<p>We try and respond by email within 12 hours, but it can take us up to 24.</p>
	</div>
{/if}
<a
	sveltekit:prefetch
	class="my-4 w-full"
	href="/onboard/"
	on:click|preventDefault={() => goto('/onboard/')}
>
	<button class="w-full border border-solid border-gray-800">See onboarding again</button>
</a>
<button
	on:click={() => window.api?.call('showSessionCoach').catch((e) => console.error(e))}
	class="w-full border border-solid border-gray-800">Show session coach</button
>
