<script>
	import { spring } from 'svelte/motion';
	export let width,
		height,
		metric,
		touched,
		coords = spring(
			{
				y: (height * 5) / 10,
				x: (width * (-5 + Number(metric.value))) / 10
			},
			{
				stiffness: 0.15,
				damping: 0.7
			}
		);

	$: metricCalc = (axis, dim) => {
		return Math.max(Math.min(5.0 - (10 * $coords[axis]) / dim, 10), 0).toFixed(1);
	};

	$: if (typeof window !== 'undefined') metric.value = metricCalc('x', -(width || 324));
</script>

<div
	class="cursor"
	style="--width: {touched ? '3.5' : '3'}rem;
  --height: {touched ? '3.5' : '3'}rem;
  transform:
    translate(clamp({-width / 2}px,{$coords.x}px, {width / 2}px),clamp({-height /
		2}px,{0}px, {height / 2}px));
    background: linear-gradient(135deg, {metric.color} 15%, {metric.color} 85%);"
/>

<style>
	.cursor {
		--width: 3rem;
		--height: 3rem;
		width: var(--width);
		height: var(--height);
		position: absolute;
		left: calc(50% - var(--width) / 2);
		top: calc(50% - var(--height) / 2);
		border-radius: 50%;
		border: none;
		pointer-events: none;
		/* transition: width 0.3s, height, 0.3s; */
	}
</style>
