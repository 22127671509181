<script context="module">
	let count = [];
</script>

<script>
	import {
		Data,
		UserEmail,
		UserInfo,
		// DataUploaded,
		// DatesUploaded,
		// GraphsCached,
		// GraphsSeen,
		// StripeInfo,
		// EthiClaims,
		// Karma,
		// Tracking,
		IsNative,
		Version,
		IsElectron,
		LiveConnections,
		TodaysData,
		DateRange,
		Loading
	} from '$lib/store.js';
	import {
		// getDataUploaded,
		// getUserInfo,
		// getCachedGraphs,
		// getKarma,
		// storeLocal,
		checkLocal,
		getTimeSeries,
		sleep
	} from '$lib/utils.js';
	import { setTodayData } from '$lib/dataProcessing.js';
	import {
		// makeDecimalIntoTime,
		setUpBackgroundObservers,
		dataGetters,
		getAppleHealthData,
		updateScreenTime,
		sync,
		query
		// calculateSleepDebt,
		// calculateSleepNeed,
		// aggregateByDay,
		// calculateScores
	} from '$lib/connectUtils.js';
	import dayjs from 'dayjs';
	import { onMount } from 'svelte';

	let finishedMounting;
	let resetDaysInPast = async () => {
		console.log('new dip', 0);
		$DateRange = {
			end: dayjs().subtract(4, 'hour').endOf('day').add(4, 'hour').hour(4).startOf('hour'),
			start: dayjs()
				.subtract(4, 'hour')
				.subtract(7, 'day')
				.endOf('day')
				.add(4, 'hour')
				.hour(4)
				.startOf('hour'),
			daysInPast: 0,
			daysInRange: Array(7)
				.fill()
				.map((p, i) =>
					dayjs()
						.subtract(4, 'hour')
						.subtract(6, 'day')
						.startOf('day')
						.add(i, 'day')
						.format('YYYY-MM-DD')
				),
			todayIn10Minutes: Array(24 * 6)
				.fill()
				.map((a, i) =>
					dayjs()
						.subtract(4, 'hour')

						.startOf('day')
						.hour(4)
						.startOf('hour')
						.add(i * 10, 'minute')
				)
		};
		// console.log($DateRange.daysInPast);
		await query('screentime')(0);
		let dayChangeInterval = setInterval(async () => {
			if ($TodaysData?.activitywatch?.productivityMetrics?.totalSessionTime) {
				clearInterval(dayChangeInterval);
			}
			await query('screentime')(0);
			setTodayData($DateRange.daysInPast, 'resetFunc');
		}, 60 * 1000 * 10);
		setTodayData($DateRange.daysInPast, 'resetFunc');
	};
	// Sync every minute

	$: todaysDayjs =
		dayjs().subtract($DateRange.daysInPast, 'day').subtract(4, 'hour') || $DateRange.end;

	let intervalFunction = async (calledFrom) => {
		if (!$UserEmail) {
			console.log("No user email, won't sync data", $UserEmail);
			return;
		}
		if (
			todaysDayjs.format('YYYY-MM-DD') !==
			dayjs().subtract(4, 'hour').subtract($DateRange.daysInPast, 'day').format('YYYY-MM-DD')
		) {
			await resetDaysInPast();
			['activitywatch', 'apple', 'whoop'].map(async (source, i) => {
				// await sleep(i * 5000);
				// console.log('updating ', source);
				let data = await dataGetters[source](0, undefined, true);

				return data;
				// .then((a) => console.log(source, ' updated'));
			});
			setTimeout(async () => {
				await setTodayData(0, calledFrom || 'intervalFunction');
				console.log('reloading from daychange');
				window.location.reload();
			}, 1000);
		}
		setTimeout(async () => {
			await dataGetters['activitywatch']($DateRange.daysInPast, undefined, true);
			setTodayData($DateRange.daysInPast, calledFrom || 'intervalFunction');
		}, Math.floor(Math.random * 1000));
	};

	// $: console.log($DateRange.daysInPast, dayjs().toISOString().slice(10), count, self);

	$: connected = {
		activitywatch: $UserInfo.syncActivityWatchEnabled,
		apple: $UserInfo.syncHKEnabled,
		whoop: $UserInfo.syncWhoopEnabled
	};
	onMount(async () => {
		if (!$UserEmail && !localStorage.ethi_access) {
			console.log('whutttttttt');
			return;
		}
		// console.log('mounted');
		window.dataGetters = dataGetters;
		window.awdata = (d) =>
			$Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[
				todaysDayjs.subtract(d, 'day').format('YYYY-MM-DD')
			];

		await Promise.all(
			['activitywatch', 'apple', 'whoop'].map(async (source, i) => {
				// await sleep(i * 5000);
				// console.log('updating ', source);
				if ($Data[`["timeseries","time_series/${source}","noCache"]`]) {
					console.log('returning', source);
					return;
				}
				console.log('setting activitywatch from cache datasyncer');
				$Data[`["timeseries","time_series/${source}","noCache"]`] = checkLocal(
					`["timeseries","time_series/${source}","noCache"]`
				);
			})
		);
		let promises = ['activitywatch', 'apple', 'whoop'].map(async (source, i) => {
			// await sleep(i * 5000);
			// console.log('updating ', source);
			let data = await dataGetters[source]($DateRange.daysInPast, undefined, true);

			return data;
			// .then((a) => console.log(source, ' updated'));
		});
		await sleep(50);
		if (
			!$Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[
				dayjs().subtract(4, 'hour').subtract($DateRange.daysInPast, 'day').format('YYYY-MM-DD')
			]
		)
			await promises?.[0];
		await sleep(50);
		// First priority - todays data
		await intervalFunction('mountFunction');
		if (typeof window !== 'undefined' && window.syncInterval) clearInterval(window.syncInterval);
		if (typeof window !== 'undefined' && !window.syncInterval)
			window.syncInterval = setInterval(intervalFunction, 50000);
		if (typeof window !== 'undefined' && !window.updateInterval)
			window.updateInterval = setInterval(updateScreenTime, 15000);

		await sleep(1000);
		// Second priority - sleep cache
		if (connected['apple']) await getAppleHealthData('sleepAnalysis', 'sleep.json');

		await sleep(1000);
		// Third priority - this weeks data if it doesn't exist
		let startOfPrevWeek = dayjs().subtract(1, 'week').startOf('week');
		let datesToAnalyse = Array(dayjs().diff(startOfPrevWeek, 'day') + 1 || 7)
			.fill()
			.map((_, i) => dayjs().subtract(4, 'hour').subtract(i, 'day').format('YYYY-MM-DD'));

		await Promise.all(
			['apple', 'activitywatch', 'whoop'].map(async (source) => {
				let i = 0;
				if (!connected[source]) return;
				for (let date of datesToAnalyse) {
					if (
						!(
							$Data?.[`["timeseries","time_series/${source}","noCache"]`]?.[date]?.lastUpdated >
							dayjs(date).add(1, 'day').valueOf()
						)
					) {
						await sleep(i * 1000);
						await dataGetters[source](i, undefined, true);
					}
					i++;
				}
				return;
			})
		);

		// Fourth priority - steps cache

		// return () => clearInterval(window.syncInterval);
		$Loading = false;
		finishedMounting = true;
		setInterval(async () => {
			if (!$TodaysData?.activitywatch?.productivityMetrics?.totalSessionTime) {
				await query('screentime')(0);
				setTodayData($DateRange.daysInPast, 'resetFunc');
			}
		}, 60 * 1000 * 10);
	});

	setInterval(() => {
		if (typeof window !== 'undefined' && !window.syncInterval) {
			window.syncInterval = setInterval(intervalFunction, 50000);
		}
		if (typeof window !== 'undefined' && !window.updateInterval) {
			clearInterval(window.updateInterval);
			window.updateInterval = setInterval(updateScreenTime, 20000);
		}
	}, 90000);

	$: if (
		$IsElectron &&
		$Version.app != 'omnipilot' &&
		$UserInfo &&
		$UserEmail &&
		typeof window !== 'undefined' &&
		!window.syncingWithactivitywatch &&
		$UserInfo.syncActivityWatchEnabled
	) {
		sync('activitywatch');
	}
	if ($UserInfo.syncHKEnabled && $IsNative) setTimeout(setUpBackgroundObservers, 15000);

	$: if (
		$IsNative &&
		$UserInfo &&
		$UserEmail &&
		typeof window !== 'undefined' &&
		!window.syncingWithapple &&
		$UserInfo.syncHKEnabled
	) {
		setTimeout(() => sync('apple'), 30000);
	}
	$: if (
		$UserInfo &&
		$UserEmail &&
		typeof window !== 'undefined' &&
		!window.syncingWithwhoop &&
		$UserInfo.syncWhoopEnabled &&
		$LiveConnections.whoop?.access_token
	) {
		setTimeout(() => sync('whoop'), 30000);
	}

	// aim here is to keep data synced between all devices, so it's always going on
	// motivation is to clean up things like sync intervals so they only exist once
	// and to have more reliable data access
	// "https://cors-anywhere.michaeljelly.repl.co/"

	// Things we care about
	// Screen time nicely normalised across sources
	// todays data all nicely packaged, i.e. debt, need, power, step, screentime, productive &
	// Magicflow data
	// types of health data all nicely normalised across sources (workouts, steps, sleep, hrv, etc.)

	// Separate stores for each major attribute?
	// do data processing once and store result, rather than raw data
	// only recalculating things if necessary

	// list of things that need to be absolutely live should be small
	// have a few things that are less likely to update

	// if todays date changes, update data for that
	// setInterval(sync, 60000)
</script>
