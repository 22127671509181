<script>
	import AreaLineGraph from '../components/charts/chartFunctions/AreaLineGraph.svelte';
	import NiceTable from '../components/NiceTable.svelte';
	import LifeDashboard from './LifeDashboard.svelte';
	import Modal from '../components/Modal.svelte';
	import Icon from '../components/Icon.svelte';
	import { IsNative, UserEmail, UserInfo, Data, Leaderboards, Loading } from '$lib/store.js';
	import { Path } from '$lib/store.js';
	import { onMount } from 'svelte';
	import { page } from '$app/stores';
	import { postUserInfo, sleep, getUserInfo } from '$lib/utils.js';
	import {
		leaderboardTask,
		extractLeaderboardMetrics,
		makeDecimalIntoTime
	} from '$lib/connectUtils';
	import dayjs from 'dayjs';

	let show;
	onMount(async () => {
		show = $page.url.searchParams.get && $page.url.searchParams.get('show');
		await getUserInfo();
		leaderboardTask('leaderboard', $UserInfo.groups).then((a) => (loading = false));
		console.log('getting leaderboard for groups', $UserInfo);
		// console.log({ show });
	});
	let loading = true;
	$: if (show) {
		// $Loading = true;
		leaderboardTask('leaderboard', [show]).then((a) => (loading = false));
	}
	let todaysDayjs = dayjs().subtract(4, 'hour');
	let todaysDate = todaysDayjs.format('YYYY-MM-DD');
	// $Leaderboards.forEach(
	// 	(l) =>
	// 		(l.leaderboard[todaysDate] = [
	// 			{
	// 				username: 'michaeljelly',
	// 				productivityScore: 0.8733793270071779,
	// 				ratio: 0.9781190707755445,
	// 				sessionLength: 3705.7629999999995,
	// 				totalSessionTime: 22234.577999999998,
	// 				traction: 19419.2207699286,
	// 				contextSwitchRate: 7.12403896309613,
	// 				appSwitchRate: 59.25905137484508,
	// 				appSwitches: 366,
	// 				contextSwitches: 44,
	// 				periodInMinutes: 1439.9999833333334,
	// 				options: { start: '2022-05-04T11:00:00.000Z', end: '2022-05-05T10:59:59.999Z' }
	// 			},
	// 			{
	// 				username: 'ansonkao' + l.id,
	// 				productivityScore: 0.8733793270071779,
	// 				ratio: 0.9781190707755445,
	// 				sessionLength: 3705.7629999999995,
	// 				totalSessionTime: 22234.577999999998,
	// 				traction: 19849.2207699286,
	// 				contextSwitchRate: 7.12403896309613,
	// 				appSwitchRate: 59.25905137484508,
	// 				appSwitches: 366,
	// 				contextSwitches: 44,
	// 				periodInMinutes: 1439.9999833333334,
	// 				options: { start: '2022-05-04T11:00:00.000Z', end: '2022-05-05T10:59:59.999Z' }
	// 			}
	// 		])
	// );
	let selectedLeaderboard = $Leaderboards?.[0];
	$: if (!selectedLeaderboard) selectedLeaderboard = $Leaderboards?.[0];
	// $: console.log({ selectedLeaderboard });
	$: selectedGroup = selectedLeaderboard?.id;
	let metrics = {
		traction: {
			multiplier: 1 / 360,
			displayName: 'Traction',
			metric: 'traction',
			accumulates: true
		},
		productivityScore: {
			multiplier: 100,
			displayName: 'Productivity',
			metric: 'productivityScore',
			accumulates: false
		},
		totalSessionTime: {
			multiplier: 1 / 3600,
			displayName: 'Total time',
			metric: 'totalSessionTime',
			accumulates: true
		},
		ratio: {
			multiplier: 100,
			displayName: 'Time working',
			metric: 'ratio',
			accumulates: false
		},
		contextSwitchRate: {
			multiplier: 1,
			displayName: 'Context switch rate',
			metric: 'contextSwitchRate',
			accumulates: false
		}
	};
	let selectedMetric = metrics['traction'];
	let selectedTimePeriod = 'week';
	let joinGroup;
	let groupToJoin;
	async function join(group) {
		$UserInfo.username = username;
		if ($UserInfo.groups?.includes(group)) {
			joinGroup = false;
			return;
		}
		$UserInfo.groups = [...($UserInfo.groups || []), group];
		postUserInfo();
		await leaderboardTask(
			'updateLeaderboard',
			$UserInfo.groups,
			username,
			extractLeaderboardMetrics($Data['["timeseries","time_series/activitywatch","noCache"]']),
			'force'
		);
		await sleep(100);
		selectedLeaderboard = $Leaderboards.find((l) => l.id === group);
		joinGroup = false;
	}
	// $: console.log(
	// 	extractLeaderboardMetrics($Data['["timeseries","time_series/activitywatch","noCache"]'])
	// );
	let username;
	$: if ($UserInfo.username) username = $UserInfo.username || '';
	setTimeout(() => {
		if ($UserInfo.groups && $Data['["timeseries","time_series/activitywatch","noCache"]'])
			leaderboardTask(
				'updateLeaderboard',
				$UserInfo.groups,
				username,
				extractLeaderboardMetrics($Data['["timeseries","time_series/activitywatch","noCache"]'])
			);
	}, 10000);

	// $: console.log(
	// 	todaysDate,
	// 	($Data['["timeseries","time_series/activitywatch","noCache"]'] || {})[todaysDate]
	// );
	$: todaysLeaderboardMetrics = extractLeaderboardMetrics(
		Object.fromEntries([
			[
				todaysDate,
				($Data['["timeseries","time_series/activitywatch","noCache"]'] || {})[todaysDate]
			]
		])
	);
	$: if (todaysLeaderboardMetrics && Object.values(todaysLeaderboardMetrics).length)
		$Leaderboards = $Leaderboards.map((l) => {
			if (!l.leaderboard || typeof l.leaderboard !== 'object') l.leaderboard = {};
			l.leaderboard[todaysDate] = [
				...(l.leaderboard[todaysDate] || []).filter((m) => m.username !== $UserInfo.username),
				{ ...(todaysLeaderboardMetrics[todaysDate] || []), username: $UserInfo.username }
			];
			return l;
		});
	let dataIndex = (todaysDayjs.day() + 6) % 7;
	let startOfWeek = todaysDayjs
		.subtract(
			dataIndex +
				(selectedTimePeriod === 'week'
					? 0
					: selectedTimePeriod === 'last week'
					  ? 7
					  : selectedTimePeriod === 'month'
					    ? 30
					    : 60),
			'day'
		)
		.startOf('day');
	$: startOfWeek = todaysDayjs
		.subtract(
			dataIndex +
				(selectedTimePeriod === 'week'
					? 0
					: selectedTimePeriod === 'last week'
					  ? 7
					  : selectedTimePeriod === 'month'
					    ? 30
					    : 60),
			'day'
		)
		.startOf('day');
	let dayjsToAnalyse = Array(selectedTimePeriod.includes('week') ? 7 : 30)
		.fill()
		.map((_, i) => startOfWeek.add(i, 'day'));
	$: datesToAnalyse = dayjsToAnalyse.map((day) => day.format('YYYY-MM-DD'));
	$: dayjsToAnalyse = Array(selectedTimePeriod.includes('week') ? 7 : 30)
		.fill()
		.map((_, i) => startOfWeek.add(i, 'day'));
	let datesToAnalyse = dayjsToAnalyse.map((day) => day.format('YYYY-MM-DD'));
	$: datesToAnalyse = dayjsToAnalyse.map((day) => day.format('YYYY-MM-DD'));

	$: thisWeeksMetrics = Object.fromEntries(
		datesToAnalyse.map((date) => {
			return [date, selectedLeaderboard?.leaderboard && selectedLeaderboard?.leaderboard[date]];
		})
	);

	$: users = Array.from(
		new Set(
			Object.values(thisWeeksMetrics)
				.flat()
				.map((l) => l?.username)
				.filter((a) => a)
		)
	);
	$: if (users.length) loading = false;
	$: leaderboardData = $Leaderboards.map((leaderboard) => {
		let metrics = Object.fromEntries(
			datesToAnalyse.map((date) => {
				return [date, leaderboard?.leaderboard && leaderboard?.leaderboard[date]];
			})
		);
		let users = Array.from(
			new Set(
				Object.values(metrics)
					.flat()
					.map((l) => l?.username)
					.filter((a) => a)
			)
		);
		let leaderboardSorted = ((users.length && users) || [''])
			.map((user) => {
				return [
					user,
					datesToAnalyse.map((_, i) =>
						i > dataIndex && selectedTimePeriod === 'week'
							? undefined
							: datesToAnalyse
									.slice(0, i + 1)
									.map(
										(date) =>
											Math.round(
												(((metrics[date] &&
													metrics[date].find((metric) => metric.username === user)) ||
													{})[selectedMetric.metric] || 0) *
													100 *
													((metrics[selectedMetric.metric] || {}).multiplier || 1)
											) / 100
									)
									.reduce((a, b) => (selectedMetric.accumulates ? a + b : b), 0)
					)
				];
			})
			.map(([username, data]) => [
				username,
				(selectedMetric.accumulates
					? data[
							selectedTimePeriod === 'week'
								? dataIndex
								: selectedTimePeriod === 'last week'
								  ? 6
								  : 28
					  ]
					: data
							?.slice(
								0,
								(selectedTimePeriod === 'week'
									? dataIndex
									: selectedTimePeriod === 'last week'
									  ? 6
									  : 28) + 1
							)
							.reduce((a, b) => a + Number(b), 0) / data.filter((d) => Number(d)).length) || 0
			])
			.sort((a, b) => b[1] - a[1])
			.map(([username, value], index) => [
				index + 1,
				username,
				selectedMetric.metric === 'totalSessionTime'
					? makeDecimalIntoTime(value, 'durationNoZero')
					: value.toFixed(selectedMetric.metric === 'ratio' ? 2 : 1) //Math.round(value * 100 * (metrics[selectedMetric.metric] || {}).multiplier || 1) / 100
			]);
		return leaderboardSorted;
	});
	$: leaderboardSorted = ((users.length && users) || ['']).map((user) => {
		return [
			user,
			datesToAnalyse.map((_, i) =>
				i > dataIndex && selectedTimePeriod === 'week'
					? undefined
					: datesToAnalyse
							.slice(0, i + 1)
							.map(
								(date) =>
									Math.round(
										(((thisWeeksMetrics[date] &&
											thisWeeksMetrics[date].find((metric) => metric.username === user)) ||
											{})[selectedMetric.metric] || 0) *
											100 *
											((metrics[selectedMetric.metric] || {}).multiplier || 1)
									) / 100
							)
							.reduce((a, b) => (selectedMetric.accumulates ? a + b : b), 0)
			)
		];
	});

	$: console.log({ leaderboardSorted });
	$: console.log({ $Leaderboards, todaysLeaderboardMetrics, todaysDate });
</script>

<Modal bind:show={joinGroup}>
	<div class="flex flex-col items-stretch w-3/4 gap-4 mx-auto">
		<label class="text-sm font-semibold"
			>YOUR NAME (e.g. Mike)
			<input
				class="bg-white bg-opacity-10 w-full text-base p-2"
				disabled={$UserInfo.username}
				bind:value={username}
			/>
		</label>
		<label class="text-sm font-semibold"
			>GROUP TO JOIN/CREATE (e.g. public)
			<input
				class="bg-white bg-opacity-10 w-full text-base p-2"
				placeholder="public"
				bind:value={groupToJoin}
			/>
		</label>
		<button
			disabled={!groupToJoin || !username}
			class="flip"
			on:click={() => {
				join(groupToJoin);
			}}>Join</button
		>
	</div>
</Modal>

<LifeDashboard contentSlot={!show} showcaseSlot={true} showWhenPathIs={'/social'}>
	<div slot="content" class="w-full mb-4 h-full flex flex-col items-stretch justify-between">
		{#if !show}
			<div>
				<div
					class="rounded-2xl p-6 pt-4"
					style=" background:linear-gradient(153.72deg, #0A196A 28.26%, #1535C9 73%, #316FE2 92.24%, #387FE1 95.76%);"
				>
					<h3 class="font-bold">Your metrics today</h3>
					<div class="grid items-center gap-2 text-sm" style="grid-template-columns: 1fr auto;">
						<div class="opacity-70">{$UserEmail}</div>
						<div class="opacity-70">{todaysDate}</div>
						{#each Object.entries(todaysLeaderboardMetrics[todaysDate] || {}) as [metric, value]}
							<div class="flex gap-1 items-center">
								<Icon
									attribute={metric}
									includeText={true}
									glowing={false}
									color={'#def'}
									forceUppercase={false}
									textOverride={metrics[metric].displayName}
								/>
							</div>
							<div class="font-semibold text-base text-right">
								{metric === 'totalSessionTime'
									? makeDecimalIntoTime(value * metrics[metric].multiplier, 'durationNoZero')
									: (value * metrics[metric].multiplier).toFixed(
											metric === 'ratio' || metric === 'productivityScore' ? 0 : 1
									  )}{metric === 'ratio' ? '%' : ''}
							</div>
						{/each}
					</div>
				</div>
			</div>
			<div>
				<div class="flex items-center justify-between">
					<h3 class="font-bold">Your groups</h3>
					<div class="flex gap-4 text-sm items-center justify-between">
						<button
							id="newGroup"
							class="px-3 py-0 bg-white text-black text-gray-300'}"
							on:click={() => {
								joinGroup = true;
							}}>Join +</button
						>
						{#if selectedGroup}
							<button
								class="px-3 py-0"
								on:click={() => {
									if (confirm('Leave ' + selectedGroup + '?')) {
										if (users?.length <= 1) leaderboardTask('deleteLeaderboard', [selectedGroup]);
										else
											leaderboardTask(
												'leaveLeaderboard',
												[selectedGroup],
												$UserInfo.username || username
											);
										$Leaderboards = $Leaderboards?.filter((l) => l.id !== selectedGroup);
										selectedLeaderboard = $Leaderboards?.[0];
										$UserInfo.groups = $UserInfo.groups?.filter((g) => g !== selectedGroup);
										postUserInfo();
									}
								}}>Leave -</button
							>
						{/if}
					</div>
				</div>
				<div class="grid flex-wrap items-center p-2 mb-4 gap-2 bg-gray-900 rounded-2xl">
					<div
						class=" cursor-pointer capitalize text-sm font-semibold inline-flex justify-between rounded-full items-center px-3 py-0.5"
					>
						{#each ['GROUP', 'POSITION'] as header}
							<div class="text-xs font-semibold text-gray-400">{header}</div>
						{/each}
					</div>
					{#each $Leaderboards.map((l) => l.id) as id, i}
						<div
							class="{id === selectedGroup
								? 'bg-gray-800 text-white'
								: 'bg-transparent text-gray-300'} cursor-pointer capitalize text-sm font-semibold inline-flex justify-between rounded-full items-center px-3 py-0.5"
							on:click={() => {
								selectedLeaderboard = $Leaderboards.find((board) => board.id === id);
							}}
						>
							<span>
								{#if id === 'f.inc'}
									<img
										src="/logos/finc_horiz_logo.png"
										class="h-4"
										style="filter:none;"
										alt="f.inc logo"
									/>
								{:else}
									{id}
								{/if}
							</span>
							<span
								>#{1 +
									leaderboardData[i].findIndex(
										([_, user]) => ($UserInfo.username || username) === user
									) || '-'}</span
							>
						</div>
					{/each}
				</div>
				{#if !$UserInfo.groups?.length}
					<p class="opacity-90">You're not in any groups yet.</p>
					<p class="opacity-90">
						Groups let you share your progress with friends, teammates or the whole world to keep
						you accountable and add some spicy competition into your life!
					</p>
					<p class="opacity-90">Invite some friends, or join the 'public' leaderboard!</p>
				{/if}
			</div>
		{/if}
	</div>
	<div
		slot="showcase"
		class="{loading
			? 'animate-pulse'
			: ''} p-4 mt-4 w-full h-full flex flex-col items-stretch justify-between"
	>
		<h3 class="font-bold mt-4">
			Leaderboard for <span
				class="ml-2 inline-flex items-center px-3 text-base font-semibold py-0.5 rounded-full bg-gray-700 text-white"
				>{#if selectedGroup === 'f.inc'}
					<img src="/logos/finc_horiz_logo.png" class="h-4" style="filter:none;" alt="f.inc logo" />
				{:else}
					{selectedGroup || '--'}
				{/if}</span
			>
		</h3>

		<div class="flex my-2 capitalize justify-between text-sm items-center gap-4">
			<select
				class="flex font-semibold text-sm items-center justify-between gap-4 p-0.5 bg-gray-700 rounded-full bg-opacity-80"
				bind:value={selectedMetric}
			>
				{#each Object.values(metrics) as metric}
					<option
						class="px-3 pt-0 pb-0.5 {selectedMetric.displayName === metric.displayName
							? 'bg-gray-800 text-white'
							: 'bg-transparent text-gray-300'}"
						value={metric}>{metric.displayName}</option
					>
				{/each}
			</select>
			<div
				class="flex capitalize justify-between text-sm items-center gap-4 p-0.5 bg-gray-700 rounded-full bg-opacity-80"
			>
				{#each ['week', 'last week', 'month', 'last month'] as timePeriod}
					<button
						class="px-3 capitalize pt-0 pb-0.5 {selectedTimePeriod === timePeriod
							? 'bg-gray-800 text-white'
							: 'bg-transparent text-gray-300'}"
						on:click={() => {
							loading = true;
							selectedTimePeriod = timePeriod;
							setTimeout(() => (loading = false), 1000);
						}}>{timePeriod}</button
					>
				{/each}
			</div>
		</div>
		{#key leaderboardSorted}
			<div class="h-96">
				<AreaLineGraph
					options={{
						data: leaderboardSorted.map(([u, dataset]) => [
							...(selectedMetric.accumulates ? [0] : []),
							...dataset
						]),
						valueFormatter: (v) =>
							(selectedMetric.metric === 'totalSessionTime'
								? makeDecimalIntoTime(v, 'durationNoZero')
								: (v || 0).toFixed(selectedMetric.metric === 'ratio' ? 2 : 1)) || '-',
						names: users,
						tooltip: true,
						minHeight: '12rem',
						type: 'time',
						dataForEach: 'category',
						// [
						// 	Object.fromEntries(

						// 	)
						// ]
						category: [
							...(selectedMetric.accumulates ? [startOfWeek?.subtract(18, 'hour')] : []),
							...dayjsToAnalyse
						].map((a) => (selectedMetric.accumulates ? a.add(9, 'hour') : a)),
						formatString: 'ddd'
					}}
					{dataIndex}
				/>
			</div>
			<NiceTable
				data={{
					headers: ['#', 'username', selectedMetric.displayName],
					rows: leaderboardSorted
						.map(([username, data]) => [
							username,
							(selectedMetric.accumulates
								? data[
										selectedTimePeriod === 'week'
											? dataIndex
											: selectedTimePeriod === 'last week'
											  ? 6
											  : 28
								  ]
								: data
										?.slice(
											0,
											(selectedTimePeriod === 'week'
												? dataIndex
												: selectedTimePeriod === 'last week'
												  ? 6
												  : 28) + 1
										)
										.reduce((a, b) => a + Number(b), 0) / data.filter((d) => Number(d)).length) || 0
						])
						.sort((a, b) => b[1] - a[1])
						.map(([username, value], index) => [
							index + 1,
							username,
							selectedMetric.metric === 'totalSessionTime'
								? makeDecimalIntoTime(value, 'durationNoZero')
								: value.toFixed(selectedMetric.metric === 'ratio' ? 2 : 1) //Math.round(value * 100 * (metrics[selectedMetric.metric] || {}).multiplier || 1) / 100
						])
				}}
			/>
		{/key}
	</div>
</LifeDashboard>
