<script>
	import Connect from '../components/connect/Connect.svelte';
	import DownloadConnect from '../components/connect/DownloadConnect.svelte';

	import { IsNative, IsElectron } from '$lib/store.js';
	import { Path } from '$lib/store.js';
	let mainUse = ['apple', 'whoop', 'rescuetime'];
</script>

<section
	class="p-6"
	style={$Path.startsWith('/data')
		? ''
		: 'opacity:0;position:absolute;bottom:100%;pointer-events:none;max-width:100vw;z-index:0;'}
>
	<h1>Your data sources</h1>
	<Connect sources={mainUse} />
	{#if $IsNative || $IsElectron}
		<p class="my-4 text-center">More coming soon!</p>
	{:else}
		<DownloadConnect />
	{/if}
</section>
