<script>
	export let data = {
		headers: ['Sender name', 'Content', 'Time'],
		rows: [
			['sender_name', 'content', dayjs().format('YYYY-MM-DD')],
			['sender_name', 'content', dayjs().format('YYYY-MM-DD')]
		]
	};
	export let maxCellWidth = undefined;
</script>

<table class="my-4 w-full rounded overflow-hidden text-center bg-black bg-opacity-40">
	<thead class=" overflow-hidden">
		{#each data.headers as header}
			<th class="px-2 py-1 uppercase font-semibold text-sm rounded">{header}</th>
		{/each}
	</thead>
	{#each data.rows as row, index}
		<tr class=" overflow-hidden">
			{#each row as cell}
				<td class="px-2 py-1 rounded" style={maxCellWidth ? 'max-width:' + maxCellWidth : ''}>
					{@html typeof cell == 'string' && cell.startsWith('http')
						? `<a href=${cell}>${cell}</a>`
						: cell}
				</td>
			{/each}
		</tr>
	{/each}
</table>

<style>
	table {
		border-collapse: separate;
	}
	tr:hover,
	td:hover,
	thead,
	th:hover {
		background-color: #44556644;
		box-sizing: border-box;
		box-shadow: 0 4px 6px #09090909;
		position: relative;
		z-index: 2;
	}
</style>
