<script>
	import TrackerCursor from './TrackerCursor.svelte';
	import { spring } from 'svelte/motion';
	import { Haptics, ImpactStyle } from '@capacitor/haptics';
	export let latestLog;
	export let metric = {
		name: 'happiness',
		antiName: 'sad',
		value: 6.7,
		color: '#00ff53',
		bg: 'rgba(0, 255, 102, 0.15)'
	};
	const hapticsImpactMedium = async () => {
		await Haptics.impact({ style: ImpactStyle.Medium });
	};
	function pannable(node) {
		let x, y;
		const dispatch = (name, detail) => node.dispatchEvent(new CustomEvent(name, { detail }));
		const setCoords = (event) => {
			x = event.clientX;
			y = event.clientY;
		};

		function handleMousedown(event) {
			setCoords(event);
			dispatch('panstart', { x, y });
			window.addEventListener('mousemove', handleMousemove);
			window.addEventListener('mouseup', handleMouseup);
		}
		function handleMousemove(event) {
			const dx = event.clientX - x;
			const dy = event.clientY - y;
			setCoords(event);
			dispatch('panmove', { x, y, dx, dy });
		}
		function handleMouseup(event) {
			setCoords(event);
			dispatch('panend', { x, y });
			window.removeEventListener('mousemove', handleMousemove);
			window.removeEventListener('mouseup', handleMouseup);
		}
		node.addEventListener('mousedown', handleMousedown);
		return {
			destroy() {
				node.removeEventListener('mousedown', handleMousedown);
			}
		};
	}
	let width, height;
	let coords;

	function handlePanStart() {
		coords.stiffness = coords.damping = 1;
	}

	function handlePanMove(event) {
		coords.update(($coords) => ({
			x: $coords.x + event.detail.dx,
			y: $coords.y + event.detail.dy
		}));
	}

	function handlePanEnd(event) {
		// 		coords.stiffness = 0.2;
		// 		coords.damping = 0.4;
		// 		coords.set({ x: 0, y: 0 });
	}

	let touched;

	let pSBCr;
	const pSBC = (p, c0, c1, l) => {
		let r,
			g,
			b,
			P,
			f,
			t,
			h,
			i = parseInt,
			m = Math.round,
			a = typeof c1 == 'string';
		if (
			typeof p != 'number' ||
			p < -1 ||
			p > 1 ||
			typeof c0 != 'string' ||
			(c0?.[0] != 'r' && c0?.[0] != '#') ||
			(c1 && !a)
		)
			return null;
		if (!pSBCr)
			pSBCr = (d) => {
				let n = d?.length,
					x = {};
				if (n > 9) {
					([r, g, b, a] = d = d.split(',')), (n = d?.length);
					if (n < 3 || n > 4) return null;
					(x.r = i(r[3] == 'a' ? r.slice(5) : r.slice(4))),
						(x.g = i(g)),
						(x.b = i(b)),
						(x.a = a ? parseFloat(a) : -1);
				} else {
					if (n == 8 || n == 6 || n < 4) return null;
					if (n < 6) d = '#' + d[1] + d[1] + d[2] + d[2] + d[3] + d[3] + (n > 4 ? d[4] + d[4] : '');
					d = i(d.slice(1), 16);
					if (n == 9 || n == 5)
						(x.r = (d >> 24) & 255),
							(x.g = (d >> 16) & 255),
							(x.b = (d >> 8) & 255),
							(x.a = m((d & 255) / 0.255) / 1000);
					else (x.r = d >> 16), (x.g = (d >> 8) & 255), (x.b = d & 255), (x.a = -1);
				}
				return x;
			};
		(h = c0?.length > 9),
			(h = a ? (c1?.length > 9 ? true : c1 == 'c' ? !h : false) : h),
			(f = pSBCr(c0)),
			(P = p < 0),
			(t =
				c1 && c1 != 'c'
					? pSBCr(c1)
					: P
					? { r: 0, g: 0, b: 0, a: -1 }
					: { r: 255, g: 255, b: 255, a: -1 }),
			(p = P ? p * -1 : p),
			(P = 1 - p);
		if (!f || !t) return null;
		if (l) (r = m(P * f.r + p * t.r)), (g = m(P * f.g + p * t.g)), (b = m(P * f.b + p * t.b));
		else
			(r = m((P * f.r ** 2 + p * t.r ** 2) ** 0.5)),
				(g = m((P * f.g ** 2 + p * t.g ** 2) ** 0.5)),
				(b = m((P * f.b ** 2 + p * t.b ** 2) ** 0.5));
		(a = f.a),
			(t = t.a),
			(f = a >= 0 || t >= 0),
			(a = f ? (a < 0 ? t : t < 0 ? a : a * P + t * p) : 0);
		if (h)
			return (
				'rgb' + (f ? 'a(' : '(') + r + ',' + g + ',' + b + (f ? ',' + m(a * 1000) / 1000 : '') + ')'
			);
		else
			return (
				'#' +
				(4294967296 + r * 16777216 + g * 65536 + b * 256 + (f ? m(a * 255) : 0))
					.toString(16)
					.slice(1, f ? undefined : -2)
			);
	};
</script>

<div
	class="box text-white px-3"
	style="background: linear-gradient(135deg, {metric.bg} 15%, {metric.bg} 85%),  linear-gradient(45deg, transparent 15%, {metric.bg} 85%);"
>
	<div
		bind:clientWidth={width}
		bind:clientHeight={height}
		on:pointerdown|self={(event) => {
			touched = true;
			coords.update(($coords) => ({
				x: -width / 2 + event.offsetX,
				y: -height / 2 + event.offsetY
			}));
			hapticsImpactMedium();
		}}
		on:pointermove|self={(event) => {
			if (touched)
				coords.update(($coords) => ({
					x: -width / 2 + event.offsetX,
					y: -height / 2 + event.offsetY
				}));
		}}
		on:pointerup|self={(event) => {
			touched = false;
			hapticsImpactMedium();
		}}
		class="w-full h-full relative"
	>
		{#if typeof window !== 'undefined' && width}
			<TrackerCursor {touched} {width} {height} bind:metric bind:coords />
		{/if}
		<div class="metric" style="color:{metric.color}">
			<div class="absolute text-center w-full -top-6">
				{metric.name.toUpperCase()}
			</div>
			<div
				class="absolute"
				style="bottom:-1.9rem;transform: translateX(calc(-50% + clamp({0}px,{(width *
					metric.value) /
					10}px, {width}px)))"
			>
				<span>{metric.value}</span>
			</div>
			{#if latestLog}
				<div
					class="absolute"
					style="background: {pSBC(-0.7, metric.color, pSBC(-0.9, metric.color))};
      /* color: black; */
      z-index: 1000;
      position: absolute;
      left: {(width * Number(latestLog)) / 10}px;
      transform: translate(-50%, -50%);
      top: 50%;
      font-size: 0.75rem;
      font-weight: bold;
      border-radius: 50%;
      padding: 0.25rem;"
				>
					{latestLog}
				</div>
			{/if}
		</div>
		<div
			class="text-xs uppercase font-bold opacity-40 absolute left-2 -top-4"
			style="pointer-events: none;"
		>
			{metric.antiName || 'Tired'}
		</div>
		<div
			class="text-xs uppercase font-bold opacity-40 absolute right-2 -top-4"
			style="pointer-events: none;"
		>
			{metric.posName || 'Happy'}
		</div>
	</div>
	<div
		class="absolute text-sm font-bold opacity-50 centreInContext left-4"
		style="pointer-events:none;--tw-translate-x: 0;"
	>
		0
	</div>
	<div
		class="absolute text-sm font-bold opacity-50 centreInContext right-4"
		style="pointer-events:none;left:unset;--tw-translate-x: 0;"
	>
		10
	</div>
</div>

<style>
	.metric {
		font-size: 1rem;
		text-align: center;
		font-weight: 500;
		pointer-events: none;
		line-height: 1.2;
	}
	.metric span {
		font-size: 1.5rem;
	}
	.metric div {
		font-weight: 600;
	}
	.box {
		position: relative;
		cursor: pointer;
		width: clamp(200px, 100%, 400px);
		height: 3rem;
		margin: 0.5rem 0 2.5rem;
		border-radius: 2rem;
		color: white;
	}
</style>
