<script>
	// kill -15 $(lsof -i:3000 | awk 'NR==2 {print $2}')

	import {
		UserEmail,
		UserToken,
		NotPaid,
		DataUploaded,
		DatesUploaded,
		GraphsCached,
		GraphsSeen,
		Version,
		TodaysData,
		Onboarding,
		DateRange,
		Loading,
		StripeInfo,
		Data,
		EthiClaims,
		Karma,
		IsNative,
		IsElectron,
		IsDev,
		IsVisible,
		UserInfo,
		Tracking,
		LiveConnections,
		RenderDelayedContent,
		Path,
		Platform
	} from '$lib/store.js';
	import CalendarFullScreen from '../../sections/CalendarFullScreen.svelte';
	import RestartModal from '../../sections/RestartModal.svelte';
	// import Playground from '../../sections/Playground.svelte';

	import LifeDashboard from '../../sections/LifeDashboard.svelte';
	import DataSources from '../../sections/Data.svelte';

	import Settings from '../../sections/Settings.svelte';

	import Social from '../../sections/Social.svelte';
	import LoadingOrb from '../../components/LoadingOrb.svelte';
	import Modal from '../../components/Modal.svelte';
	import Snack from '../../components/Snack.svelte';
	import ContactMike from '../../components/ContactMike.svelte';
	import ChangeLog from '../../components/ChangeLog.svelte';
	import Help from '../../components/Help.svelte';

	import DataSyncer from '../../components/DataSyncer.svelte';
	import Tour from '../../components/Tour.svelte';

	import {
		getDataUploaded,
		getUserInfo,
		postUserInfo,
		getCachedGraphs,
		getAllMetadata,
		getKarma,
		checkLocal,
		storeLocal
	} from '$lib/utils.js';
	import { query } from '$lib/connectUtils.js';
	import { setTodayData } from '$lib/dataProcessing.js';
	import { getAuth, getStripeInfo } from '$lib/authapi.js';
	import { onMount } from 'svelte';
	import { goto, afterNavigate, beforeNavigate } from '$app/navigation';
	import { page } from '$app/stores';
	import { App } from '@capacitor/app';
	import * as Sentry from '@sentry/browser';
	import { Integrations } from '@sentry/tracing';
	// import { init } from 'commandbar';
	// console.log('layout baby');
	let errorReloadInterval;
	let errorReloadTimer = 10;
	// $: $SentryInstance = Sentry;
	$: $Path = (
		!$IsElectron
			? $page.url.pathname || '/'
			: ($page.url.pathname || '/')
					.split('/ui')
					.pop()
					.replace(/index.html|.html/, '')
	).replace(/(.+)\/$/, '$1');
	$: if (!$IsDev && typeof window !== 'undefined') $Path && window?.analytics?.page?.();

	let loaded = false;
	let infoFetched = false;

	let justStarted;
	// beforeNavigate((navigation) => {
	// 	console.log({ navigation, ...(navigation.to || {}) }, 'before');
	// });
	afterNavigate((navigation) => {
		console.log({ navigation });
		// $Path = navigation?.to?.pathname;
		window.analytics?.page?.(navigation?.to?.pathname, {
			platform: $Platform,
			environment: $IsDev ? 'dev' : 'production',
			email: $UserEmail,
			from: navigation?.from?.pathname
		});
	});
	let mounted;
	let shouldReload = true;
	let reloadNextInactive;
	let currentDeployedFrontEnd = process.env.VERSION + '.' + process.env.DATE;
	let latestDeployedFrontEnd;
	onMount(async () => {
		window.currentDeployedFrontEnd = currentDeployedFrontEnd;
		window.api?.remove('watcherStatus');
		window.api?.receive('watcherStatus', (watcherStatus) => {
			$Tracking.watcherStatus = watcherStatus.window;
			console.log({ watcherStatus });
		});
		(async function nice() {
			if ($Tracking.watcherStatus !== 'running' && $UserInfo.syncActivityWatchEnabled) {
				$Tracking.watcherStatus = await window.api?.call('watcher');
			}
		})();
		if (!window.api?.version && !$Version.app)
			$Version = {
				app: window.location.href.includes('omnipilot')
					? 'omnipilot'
					: new URL(window.location.href).searchParams.app ||
					  new URL(window.location.href).searchParams.get?.('app')
			};
		(window.api?.version || window.api?.call('version'))?.then((v) => {
			$Version = v || $Version;
			if (typeof $Version == 'object' && !$Version.app)
				$Version.app = $Version?.version > '0.2.0' ? 'magicflow' : 'omnipilot';
			else if (typeof $Version == 'string' && !$Version.app)
				$Version = {
					version: $Version,
					app: $Version > '0.2.0' ? 'magicflow' : 'omnipilot'
				};
			if ($Version.app == 'omnipilot') {
				// make body background black
				document.body.style.backgroundColor = 'black';
			}
			$Version.app = window.location.href.includes('omnipilot')
				? 'omnipilot'
				: $Version.app || $page.url.searchParams.get('app');
			if ($Version.app == 'omnipilot' && !window.analytics) {
				window.analytics = {};
				function juneify(writeKey) {
					window.analytics._writeKey = writeKey;
					var script = document.createElement('script');
					script.type = 'application/javascript';
					script.onload = function () {
						window.analytics?.page();
						console.log('juneify loaded');
					};
					script.src = 'https://unpkg.com/@june-so/analytics-next/dist/umd/standalone.js';
					var first = document.getElementsByTagName('script')[0];
					first.parentNode.insertBefore(script, first);
				}
				juneify('DNLXRxUVUqO8cvAT');
			}
		});
		if (window.outerHeight && window.outerHeight < 400) goto('/session');
		setInterval(async () => {
			const isOnline = navigator.onLine;
			if (isOnline && window.location.href.includes('file://')) {
				window.location = $IsDev
					? 'http://localhost:3000'
					: 'https://app.' + ($Version.app === 'omnipilot' ? 'omnipilot.ai' : 'magicflow.com');
				return;
			}

			await fetch('/version')
				.then(async (r) => (latestDeployedFrontEnd = await r.text()))
				.catch((e) => console.error(e));
			window.latestDeployedFrontEnd = latestDeployedFrontEnd;
			console.log({ latestDeployedFrontEnd, currentDeployedFrontEnd });
			if (
				latestDeployedFrontEnd &&
				currentDeployedFrontEnd &&
				latestDeployedFrontEnd.split('.').pop() - currentDeployedFrontEnd.split('.').pop() > 30000
			) {
				reloadNextInactive = true;
				localStorage.reloadCount = 0;
				console.log('ready to reload', {
					reloadTriggeredAt: dayjs().format('HH:mm DD MMM'),
					latestDeployedFrontEnd,
					currentDeployedFrontEnd
				});
				localStorage.reloadTrigger = JSON.stringify({
					reloadTriggeredAt: dayjs().format('HH:mm DD MMM'),
					latestDeployedFrontEnd,
					currentDeployedFrontEnd
				});
			}
		}, 60000);
		mounted = true;

		if (
			$IsElectron &&
			!(localStorage.ethi_refresh && localStorage.ethi_access) &&
			!localStorage.userToken
		) {
			if ($Version.app == 'omnipilot') {
				console.log('not calling authState', { localStorage, $IsElectron });
				goto('/register');
				return;
			}
			console.log('calling authState');
			try {
				let authState = await window.api
					?.call('authState')
					.catch((e) => console.error('error calling authState', e));
				console.log({ authState, localStorage, $UserEmail });
				if (authState?.refreshToken) {
					localStorage.ethi_refresh = authState.refreshToken;
					localStorage.ethi_access = authState.accessToken;
					localStorage.userToken = authState.userToken;
					$UserEmail = authState.userEmail;
				} else {
					goto('/register');
					return;
				}
			} catch {
				console.log('error calling authState', e);
			}
		} else {
			console.log('not calling authState', { localStorage, $IsElectron });
		}
		let auth = getAuth();
		shouldReload = localStorage.reloadCount ? Number(localStorage.reloadCount) < 3 : true;
		checkIfLatestVersion();
		window.goto = goto;
		window.api?.receive('update-downloaded', () => (showRestart = true));

		let _ = new Promise(async (resolve, reject) => {
			if (!$IsDev)
				Sentry.init({
					dsn: 'https://c83517685f1540c7b3447ee5f6553416@o1108485.ingest.sentry.io/6136191',
					integrations: [
						new Integrations.BrowserTracing(),
						new Sentry.Integrations.Breadcrumbs({})
						// new Sentry.Replay()
					],
					replaysSessionSampleRate: 0.1,
					// If the entire session is not sampled, use the below sample rate to sample
					// sessions when an error occurs.
					replaysOnErrorSampleRate: 1.0,
					release:
						process.env.VERSION +
						'.' +
						process.env.DATE +
						($Version?.app || window.location.host.split('.')[1]),
					// $IsElectron
					// ? $Version?.version || $Version || process.env.VERSION
					// : process.env.VERSION,
					beforeSend(event, hint) {
						// Check if it is an exception, and if so, show the report dialog
						const isNonErrorException =
							event?.exception?.values?.[0]?.value?.startsWith('Non-Error exception captured') ||
							hint?.originalException?.['message']?.startsWith('Non-Error exception captured');
						/* tslint:enable:no-string-literal only-arrow-functions */

						if (isNonErrorException) {
							// We want to ignore those kind of errors
							return null;
						}
						console.log(event, hint, 'beforeSend');
						if (event.exception && document.querySelector('#errorModal')) {
							document.querySelector('#errorModal').style.display = 'block';
							if (!errorReloadInterval && latestVersion && shouldReload)
								errorReloadInterval = setInterval(() => {
									errorReloadTimer -= 1;
									if (document.querySelector('#errorReloadTimer'))
										document.querySelector('#errorReloadTimer').innerHTML = errorReloadTimer;
									if (errorReloadTimer <= 0) {
										window.clearInterval(errorReloadInterval);
										localStorage.reloadCount = Number(localStorage.reloadCount || 0) + 1;
										console.log('reloading from error');
										window.location.reload();
									}
								}, 1000);
							if ($IsElectron && !$IsDev) window.api?.call('checkForUpdates');
							return event;
						}

						return event;
					},
					// Set tracesSampleRate to 1.0 to capture 100%
					// of transactions for performance monitoring.
					// We recommend adjusting this value in production
					tracesSampleRate: $IsDev ? 1.0 : 0.2
				});

			const originalUnhandledRejection = window.onunhandledrejection;
			// function ignoreerror() {;
			// 	return true;
			// }
			// window.onerror = ignoreerror;
			const handle_error = (e) => {
				e.preventDefault();
				Sentry.captureException(e.error);
			};
			window.addEventListener('error', handle_error);

			window.onunhandledrejection = (e) => {
				// or do
				console.error(e, e.reason, e.reason?.stack);
				Sentry.captureException(e, {
					originalException: { message: 'Non-Error exception captured' }
				});
				if (e.reason)
					Sentry.captureException(e.reason.stack || e.reason.message || e.reason, {
						originalException: { message: 'Non-Error exception captured' }
					});
				e.preventDefault();
				console.error('caught', e);
				// return true;
				// originalUnhandledRejection(e);
			};
			resolve();
		}).catch((e) => {
			console.error('asyncV', e);
		});
		// init('7d313d0a');
		if ($IsElectron && !justStarted) {
			window.api?.remove && (await window.api?.remove('justStarted'));
			window.api?.receive('justStarted', () => {
				if ($Version?.version && $Version.version !== localStorage.version) justStarted = true;
				localStorage.version = $Version?.version;
			});
		}

		await auth;

		if (
			($EthiClaims && $EthiClaims?.email) ||
			$UserEmail ||
			window.firebaseAuth?.currentUser?.email
		) {
			$UserEmail = $UserEmail || $EthiClaims?.email || window.firebaseAuth?.currentUser?.email;
			// if ($IsDev) window.CommandBar.boot(hash($UserEmail));
			Sentry.setUser({ email: $UserEmail || $EthiClaims?.email });
			getAllInfo()
				.then(async (_) => {
					if ($UserInfo.alwaysDark !== undefined) {
						if (typeof document !== 'undefined') {
							if ($UserInfo.alwaysDark) {
								document.documentElement.classList.add('alwaysDark');
							} else {
								document.documentElement.classList.remove('alwaysDark');
							}
						}
					}
					if (!$UserInfo.neurofusion) {
						try {
							fetch('https://css-server.michaeljelly.repl.co/neurofusion')
								.then(async (r) => {
									let emails = await r.json();
									if (emails?.includes($UserEmail)) {
										$UserInfo.neurofusion = true;
										postUserInfo();
									}
								})
								.catch((e) => {
									console.log(e, 'inner neurofusion error');
								});
						} catch (e) {
							console.log(e, 'outer neurofusion error');
						}
					}
					if ($Tracking.watcherStatus === 'running') {
						if (
							!$TodaysData?.activitywatch?.productivityMetrics?.totalSessionTime ||
							$TodaysData?.activitywatch?.productivityMetrics?.totalSessionTime < 10
						)
							await query('screentime')($DateRange.daysInPast);
						setTodayData($DateRange.daysInPast, 'appstatechange');
					}
				})
				.catch((e) => console.log(e, 'possible neurofusion error'));

			// if ($IsDev) window.CommandBar.addRouter((url) => goto(url.toLowerCase()));
		} else
			setTimeout(async () => {
				if (
					!($EthiClaims && $EthiClaims?.email) &&
					!$Path.includes('connected') &&
					!$Path.includes('social') &&
					!$Path.includes('register') &&
					!$Path.includes('login') &&
					($Version || (await window.api.version))?.app != 'omnipilot'
				)
					goto(
						`/login?` +
							($IsElectron
								? ''
								: `redirect=${encodeURIComponent(
										(window.location.href + '#')
											.split('')
											.map((char) => String.fromCharCode(char.charCodeAt(0) - 6))
											.join('')
								  )}&app=${$Version?.app || 'magicflow'}`)
					);
				else if ($Tracking.watcherStatus === 'running') {
					if (
						!$TodaysData?.activitywatch?.productivityMetrics?.totalSessionTime ||
						$TodaysData?.activitywatch?.productivityMetrics?.totalSessionTime < 10
					)
						await query('screentime')($DateRange.daysInPast);
					setTodayData($DateRange.daysInPast, 'appstatechange');
				}
			}, 3000);
		await App.removeAllListeners();
		App.addListener('appUrlOpen', function (event) {
			// Example url: https://beerswift.app/tabs/tabs2
			// slug = /tabs/tabs2
			const slug = event.url.split('.me').pop();

			// We only push to the route if there is a slug present
			if (slug) {
				goto(slug);
			}
		});
		if (!$IsDev)
			window.analytics?.track?.('Application Opened', {
				platform: $Platform,
				environment: $IsDev ? 'dev' : 'production',
				email: $UserEmail
			});

		let identified;
		App.addListener('appStateChange', async ({ isActive }) => {
			// console.log(
			// 	'App state changed. Is active?',
			// 	isActive,
			// 	{ $UserEmail, $EthiClaims },
			// 	$EthiClaims.identity || $EthiClaims.uid
			// );
			if ($Tracking.watcherStatus !== 'running' && $UserInfo.syncActivityWatchEnabled) {
				$Tracking.watcherStatus = await window.api?.call('watcher');
			}
			if (!isActive) {
				Object.entries($Data).forEach(([key, value]) => {
					storeLocal(key, value);
				});
				if (reloadNextInactive) {
					console.log('reloading from appStateChange', {
						reloadNextInactive,
						currentDeployedFrontEnd,
						latestDeployedFrontEnd
					});
					window.location.reload();
				}
			}
			$IsVisible = isActive;
			if ($Tracking.watcherStatus === 'running') {
				let todaysDate = dayjs().subtract(4, 'hour').format('YYYY-MM-DD');
				if (
					!$TodaysData?.activitywatch?.productivityMetrics?.totalSessionTime ||
					$TodaysData?.activitywatch?.productivityMetrics?.totalSessionTime < 10 ||
					(!$DateRange.daysInPast &&
						$Data[`["timeseries","time_series/activitywatch","noCache"]`]?.[todaysDate]
							?.lastUpdated <
							Date.now() - 1000 * 120)
				) {
					console.log(
						'updating ',
						$TodaysData?.activitywatch?.productivityMetrics?.totalSessionTime,
						!$DateRange.daysInPast,
						$TodaysData?.activitywatch?.lastUpdated,
						Date.now() - 1000 * 120,
						$TodaysData?.activitywatch?.lastUpdated < Date.now() - 1000 * 120
					);
					if (!isActive)
						setTimeout(
							async () => {
								await query('screentime')($DateRange.daysInPast, true);
								setTodayData($DateRange.daysInPast, 'appstatechange');
								storeLocal(
									`["timeseries","time_series/activitywatch","noCache"]`,
									$Data[`["timeseries","time_series/activitywatch","noCache"]`]
								);
							},
							isActive ? 8000 : 0
						);
					else query('screentime')($DateRange.daysInPast, true);
				}
			}
			if (
				!$IsDev &&
				$UserEmail &&
				$EthiClaims &&
				($EthiClaims.identity || $EthiClaims.uid) &&
				!identified
			) {
				if (window?.heap?.identify) window?.heap?.identify($UserEmail);
				window.analytics?.identify($EthiClaims.identity || $EthiClaims.uid, {
					email: $UserEmail,
					platform: $Platform,
					environment: $IsDev ? 'dev' : 'production'
				});
				identified = true;
			}
			if (!$IsDev)
				window.analytics?.track?.(isActive ? 'Application Opened' : 'Application Backgrounded', {
					platform: $Platform,
					environment: $IsDev ? 'dev' : 'production',
					email: $UserEmail
				});
		});

		let cache = checkLocal(`["timeseries","time_series/activitywatch","noCache"]`);
		console.log('setting activitywatch from cache');
		$Data[`["timeseries","time_series/activitywatch","noCache"]`] = cache;

		loaded = true;
		setTimeout(() => {
			try {
				(function (C, A, L) {
					let p = function (a, ar) {
						a.q.push(ar);
					};
					let d = C.document;
					C.Cal =
						C.Cal ||
						function () {
							let cal = C.Cal;
							let ar = arguments;
							if (!cal.loaded) {
								cal.ns = {};
								cal.q = cal.q || [];
								d.head.appendChild(d.createElement('script')).src = A;
								cal.loaded = true;
							}
							if (ar[0] === L) {
								const api = function () {
									p(api, arguments);
								};
								const namespace = ar[1];
								api.q = api.q || [];
								typeof namespace === 'string'
									? (cal.ns[namespace] = api) && p(api, ar)
									: p(cal, ar);
								return;
							}
							p(cal, ar);
						};
				})(window, 'https://app.cal.com/embed/embed.js', 'init');
				Cal('init', { origin: 'https://app.cal.com' });

				// Important: Make sure to add `data-cal-link="michaeljelly/15min"` attribute to the element you want to open Cal on click
				Cal('ui', { styles: { branding: { brandColor: '#002066' } } });
			} catch (e) {
				console.error(e);
			}
		}, 9500);
	});

	let hasStripe;

	async function getAllInfo() {
		infoFetched = true;
		let hasRunElectronAuth = false;
		if ($IsElectron && $UserInfo?.syncActivityWatchEnabled && $Version?.app != 'omnipilot') {
			hasRunElectronAuth = true;

			window.api?.remove('noAccess');
			window.api?.receive('noAccess', () => {
				$Tracking.noAccess = true;
			});
			window.api
				?.call('authenticated', [
					$UserEmail,
					$IsDev,
					$EthiClaims?.uid || $EthiClaims?.identity || window.firebaseAuth?.currentUser?.uid,
					localStorage.ethi_refresh,
					localStorage.ethi_access,
					localStorage.userToken
				])
				.then(() => {
					query('screentime')($DateRange.daysInPast);
				});
		}
		let userInfoPromise = getUserInfo('getAllInfoLayout').then(async (a) => {
			if (!$UserEmail) {
				goto(
					`/login?` +
						($IsElectron
							? ''
							: `redirect=${encodeURIComponent(
									(window.location.href + '#')
										.split('')
										.map((char) => String.fromCharCode(char.charCodeAt(0) - 6))
										.join('')
							  )}&app=${$Version?.app || 'magicflow'}`)
				);
				return;
			}
			if (
				$IsElectron &&
				$UserInfo?.syncActivityWatchEnabled &&
				!hasRunElectronAuth &&
				$Version?.app != 'omnipilot'
			) {
				hasRunElectronAuth = true;

				window.api?.remove('noAccess');
				window.api?.receive('noAccess', () => {
					$Tracking.noAccess = true;
				});
				window.api
					?.call('authenticated', [
						$UserEmail,
						$IsDev,
						$EthiClaims?.uid || $EthiClaims?.identity || window.firebaseAuth?.currentUser?.uid,
						localStorage.ethi_refresh,
						localStorage.ethi_access,
						localStorage.userToken
					])
					.then(() => {
						query('screentime')($DateRange.daysInPast);
					});
			}
			if ($IsElectron && $UserInfo.updateSilently) {
				window.api?.call('updateSilently');
			}
			if (
				$IsElectron &&
				$UserInfo?.lastUpdated &&
				(!$UserInfo.syncActivityWatchEnabled ||
					($Version?.app == 'omnipilot' && !$UserInfo.onboardingComplete)) &&
				!$Path.includes('onboard') &&
				$Platform === 'macos'
			)
				goto('/onboard');
			else if (
				$IsElectron &&
				$UserInfo?.lastUpdated &&
				!$UserInfo.syncActivityWatchEnabled &&
				$UserInfo.onboardingComplete &&
				$Version?.app !== 'omnipilot'
			) {
				$UserInfo.syncActivityWatchEnabled = true;
				postUserInfo();
				window.api?.call('authenticated', [
					$UserEmail,
					$IsDev,
					$EthiClaims?.uid || $EthiClaims?.identity || window.firebaseAuth?.currentUser?.uid,
					localStorage.ethi_refresh,
					localStorage.ethi_access,
					localStorage.userToken
				]);
				if (!$IsDev)
					window.analytics?.track?.('Connected Source', {
						source: 'activitywatch',
						platform: $Platform,
						environment: $IsDev ? 'dev' : 'production',
						email: $UserEmail
					});
				// window.location.reload()
				setTimeout(async () => {
					query('screentime')(0, 'refresh');
					$DateRange.daysInPast = $DateRange.daysInPast;
				}, 10000);
			}
		});
		getStripeInfo();
		let dataUploadedCache;
		dataUploadedCache = checkLocal('dataUploaded');
		if (dataUploadedCache) $DataUploaded = dataUploadedCache;
		getDataUploaded();
		getAllMetadata(); // stores and localstorage handled in function
		let graphsCachedCache = checkLocal('graphsCached');
		if (graphsCachedCache) $GraphsCached = graphsCachedCache;
		getCachedGraphs().then((graphs) => {
			if (!(graphs && graphs?.length)) return;
			let cachedGraphs = graphs?.filter((graph) => graph[1]).map((graph) => graph?.[0]);
			if (cachedGraphs && Object.keys(cachedGraphs).length) {
				$GraphsCached = cachedGraphs;
				storeLocal('graphsCached', $GraphsCached);
			}
		});
		if (localStorage.k) {
			$Karma = localStorage.k;
		}
		await getKarma().then((res) => {
			$Karma = (res && res.data) || 0;
			localStorage.k = $Karma;
			return $Karma;
		});
	}
	$: $NotPaid =
		$UserEmail &&
		typeof window !== 'undefined' &&
		$StripeInfo &&
		$UserToken &&
		!$UserInfo.skipPayment &&
		!$UserInfo.neurofusion &&
		dayjs(Number($UserToken?.metadata?.createdAt) || 0) > dayjs(1655863733202) &&
		// dayjs() > dayjs(Number($UserToken?.metadata?.createdAt) || 0).add(7, 'day') &&
		$StripeInfo?.subscriptions &&
		!$StripeInfo?.subscriptions?.total_count;
	// $: if (typeof window !== 'undefined')
	// 	console.log({ $NotPaid }, $UserToken, {
	// 		skip: !$UserInfo?.skipPayment,
	// 		afterPaywall: dayjs(Number($UserToken?.metadata?.createdAt) || 0) > dayjs(1655863733202),
	// 		sevenDays: dayjs() > dayjs(Number($UserToken?.metadata?.createdAt) || 0).add(7, 'day'),
	// 		StripeInfo: $StripeInfo,
	// 		notSubscribed: !$StripeInfo?.subscriptions?.total_count
	// 	});

	let showCTA;

	function show() {
		$RenderDelayedContent = true;
	}
	setTimeout(show, 8000);

	let showHelp = false;
	let onboardingComplete = !!$UserInfo.onboardingComplete;
	$: if (!onboardingComplete && $UserInfo.onboardingComplete)
		onboardingComplete = !!$UserInfo.onboardingComplete;
	$: if (onboardingComplete && !$UserInfo.onboardingComplete) onboardingComplete = false;
	// $: console.log({ onboardingComplete });

	let showRestart;
	let latestVersion = true;
	async function checkIfLatestVersion() {
		let version;
		function replaceAt(str, index, replacement) {
			return str?.substring(0, index) + replacement + str?.substring(index + replacement.length);
		}
		try {
			let versionPromise = await fetch(
				'https://cors.magicflow.workers.dev/?https://storage.googleapis.com/ethi_app/dist/latest-mac.yml'
			).then(async (x) => (await x.text())?.split('\n')?.[0]?.slice(9));
			if (versionPromise?.length) {
				version = replaceAt(
					versionPromise,
					versionPromise.length - 1,
					(Number(versionPromise[versionPromise.length - 1]) + 1).toString()
				);
			} else {
				const json = JSON.parse(await readFile('../native/package.json'));
				version = json?.version;
			}
			console.log('version', version);
		} catch (e) {
			console.log(e, 'vite.config');
		}
		if (version?.length) {
			if (
				Number(version[version.slice(-2)]) >=
				Number(($Version?.version || (typeof $Version == 'string' && $Version) || '99').slice(-2))
			)
				latestVersion = false;
		}
		return version;
	}
	// $: console.log(loaded, $UserEmail, onboardingComplete, !$Path.includes('onboard'));
	let test = () => console.log('agwegaet');
</script>

<svelte:head>
	{#if $IsNative}
		<meta
			name="viewport"
			content="viewport-fit=cover, user-scalable=no, initial-scale=1, maximum-scale=1, minimum-scale=1"
		/>
	{/if}
	{#if !$Path.includes('login') && !$Path.includes('register')}
		<meta name="robots" content="noindex" />
	{/if}

	<title>
		{$Path
			.split('/')
			.filter((a) => a)
			.map((w) => w?.[0].toUpperCase() + w.substring(1).toLowerCase().replace(/-_/g, ' '))
			.join(' - ') || 'Your Data OS'} • Magicflow
	</title>
</svelte:head>

{#if $UserEmail && loaded && $Version?.app != 'omnipilot'}
	{#key onboardingComplete}
		<DataSyncer />
	{/key}
{/if}

{#if justStarted}
	<ChangeLog />
{/if}

<main class="flex w-full h-screen-safe overflow-x-hidden align-stretch relative">
	{#if !($Path.includes('register') || $Path.includes('login') || $Path.includes('onboard') || $Path.includes('action'))}
		<!-- <NavColumn /> -->
		<Modal bind:show={$Tracking.showTimeline} options={{ topLevel: true, fullScreen: true }}>
			<CalendarFullScreen />
		</Modal>
		<!-- <Toast
			bind:showToast={$Tracking.mood.tracking}
			css={`bottom:${$IsNative || $IsElectron ? '5rem;height:82vh;' : '1rem;height:90vh;'}${
				$Tracking.mood.tracking ? '' : 'z-index: 49'
			}`}
			onClose={() => {
				$Tracking.mood.tracking = false;
			}}
		>
			{#if $Tracking.mood.tracking}
				<MoodTracker />
			{/if}
		</Toast> -->
	{/if}
	<div id="content" class="flex-grow flex-shrink flex flex-col align-stretch h-screen-safe">
		<div class="flex-grow flex-shrink relative overflow-hidden h-full">
			{#if (!loaded || $Version?.app == 'omnipilot') && !$UserEmail}
				<LoadingOrb {loaded} />
			{/if}

			<slot />

			{#if $Version?.app != 'omnipilot'}
				{#if $UserEmail}
					{#key onboardingComplete}
						<LifeDashboard />
						{#if $RenderDelayedContent || !$Path.includes('onboard')}
							<!-- <Insights /> -->
							<!-- <DataDive /> -->
							<DataSources />
						{/if}
						<!-- {#if !$Path.includes('onboard') && !$Path.includes('login') && !$Path.includes('register') && !$page?.url?.search.includes('show')}
					
						<NavColumn
							includeControls={false}
							classes={'fixed left-6 bottom-6 z-10'}
							style={'width:calc(max(33.3%, min(420px, 100vw)) - 3rem)'}
						/>
					{/if} -->
					{/key}
				{/if}

				<Social />
			{/if}
			<Settings />
			<!-- {#if $UserEmail && showCTA}
				<CTA bind:showCTA />
			{/if} -->
		</div>
	</div>
</main>
<!-- <Modal show={true}>
	<Copyable textToCopy={'about:config'}>about:config</Copyable>
</Modal> -->
{#if $IsDev || (!$IsNative && !$IsElectron) || $Path.includes('/profile')}
	<a
		class="fixed z-50 left-36 bottom-4 rounded-full {$NotPaid ? 'z-[10010]' : ''}"
		href="mailto:mike@{$Version.app === 'omnipilot' ? 'omnipilot.ai' : 'magicflow.com'}"
	>
		<img
			class="rounded-full h-8 w-8 shadow-md"
			src="https://uploads-ssl.webflow.com/5e2da1d25b8bed7662b12293/5f8423dddc86b5b8de5707fd_Photo%20of%20Mike%20Jelly-p-800.jpeg"
			alt="Mike's face"
		/>
		<svg
			class="absolute h-4 w-4 -bottom-1.5 -right-0.5 text-white bg-white
    bg-opacity-10 opacity-80 rounded-full"
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 20 20"
			fill="currentColor"
		>
			<path d="M2.003 5.884L10 9.882l7.997-3.998A2 2 0 0016 4H4a2 2 0 00-1.997 1.884z" />
			<path d="M18 8.118l-8 4-8-4V14a2 2 0 002 2h12a2 2 0 002-2V8.118z" />
		</svg>
	</a>
	<button
		on:click={() => (showHelp = !showHelp)}
		class="fixed z-50 flex items-center justify-center left-20 bottom-4
  rounded-full h-8 w-8 p-1 shadow-md bg-gradient-to-br from-gray-100
  via-gray-300 to-gray-100 text-gray-800"
	>
		{#if !showHelp}
			<svg
				class="h-5 w-5"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-8-3a1 1 0 00-.867.5 1 1 0
        11-1.731-1A3 3 0 0113 8a3.001 3.001 0 01-2 2.83V11a1 1 0 11-2 0v-1a1 1 0
        011-1 1 1 0 100-2zm0 8a1 1 0 100-2 1 1 0 000 2z"
					clip-rule="evenodd"
				/>
			</svg>
		{:else}
			<svg
				class="h-5 w-5"
				xmlns="http://www.w3.org/2000/svg"
				viewBox="0 0 20 20"
				fill="currentColor"
			>
				<path
					fill-rule="evenodd"
					d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414
        1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1
        1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586
        8.707 7.293z"
					clip-rule="evenodd"
				/>
			</svg>
		{/if}
	</button>
	{#if showHelp}
		<Modal bind:show={showHelp}>
			<Help page={$Path} />
		</Modal>
	{/if}
{/if}
{#if $Version?.inAppFolder === false}
	<Modal>
		Magicflow needs to be moved to your Applications folder to work properly. Please move it there
		and quit and restart the app!
	</Modal>
{/if}
{#if $LiveConnections?.whoop?.needsWhoopCredentials}
	<Modal
		show={$LiveConnections?.whoop?.needsWhoopCredentials}
		on:close={() => ($LiveConnections.whoop = { hasSeenModal: true })}
	>
		<div class="uppercase text-center font-semibold mt-2">Reconnect with whoop</div>
		<img src="/logos/whoop_logo.svg" class="w-20 mx-auto my-8" alt="whoop logo" />
		<p class="text-center">
			You've connected your Whoop account to Magicflow, but to sync/keep syncing your data on this
			device, you need to sign in again.
		</p>
		<div class="text-center my-8">
			<button
				on:click={() => {
					$LiveConnections.whoop = { hasSeenModal: true };
					goto('/whoop');
				}}
				class="flip rounded-full">Reconnect with Whoop</button
			>
		</div>
	</Modal>
{/if}
{#if $IsDev && loaded && $UserEmail && onboardingComplete && !$Path.includes('onboard')}
	<!-- <Paywall>
		<TimeTracked show={['activities']} divName="SubscribePreview" showImpacts={false} />
		<div class="h-4" />
	</Paywall>  -->
	<!-- <Tour /> -->
{/if}

<div
	id="errorModal"
	class="fixed centreInContext bg-gray-900 p-8"
	style={'display:none;width:400px;z-index:10002'}
>
	<p>Oops, we had an error! The team has been notified and is working on a fix.</p>
	{#if !latestVersion}
		<p>
			You're using an old version of Magicflow. {window.api ? 'Restarting' : 'Reloading'} will update
			the app and may fix this error.
		</p>
		<button on:click={() => window.api?.call('quitAndRestart') || window.location.reload()}>
			Restart
		</button>
	{:else if shouldReload}
		<p>Reloading may fix it for now.</p>
		<p>
			Reloading the window in <span id="errorReloadTimer" class="font-bold">10</span> seconds.
		</p>
		<button on:click={() => window.location.reload()}> Reload now </button>
		<button on:click={() => window.clearInterval(errorReloadInterval)}> Cancel reload </button>
	{:else}
		<p>We tried reloading but it didn't fix the error, you may have to wait for the fix.</p>
		<p>Sorry about that! The app may not function normally in the meantime</p>
		<button on:click={() => window.location.reload()}> Reload again </button>
		<button
			on:click={(e) => {
				// console.log(e);
				if (e?.target?.parentElement?.style?.display) e.target.parentElement.style.display = 'none';
			}}
		>
			Dismiss modal
		</button>
	{/if}
</div>
<RestartModal bind:showRestart />

{#if $Tracking.noAccess}
	<Modal bind:show={$Tracking.noAccess} options={{ fullScreen: true }}>
		<div>It looks like you haven't got Magicflow fully set up on your device.</div>
		<div>
			You can set it up by <a
				href="https://app.{$Version.app === 'omnipilot' ? 'omnipilot.ai' : 'magicflow.com'}/onboard"
				>starting onboarding again</a
			>
			and following the instructions.
		</div>
		<div class="text-center">
			<button
				on:click={() => {
					$Tracking.noAccess = false;
					goto('/onboard');
				}}
				class="rounded-full"
			>
				Set up Magicflow
			</button>
		</div></Modal
	>
{/if}

{#if $Path === '/' && $Version?.app != 'omnipilot'}
	<Tour />
{/if}

<style lang="postcss">
	main {
		flex-direction: column-reverse;
	}
	main > div.flex-grow {
		overflow: auto;
	}
	.bottom-4 {
		@apply left-4;
	}
	.left-36 {
		display: none;
	}
	.left-28 {
		display: none;
	}
	.left-20 {
		bottom: 4.5rem;
	}
	:global(
			[aria-label='category-Navigation'],
			#commandbar-menu__wrapper > div > div > div > div:nth-child(1) > div
		) {
		background: #1535c9cc !important;
	}

	:global(body:not(.playground)) {
		user-select: none;
		-moz-user-select: none;
		-webkit-user-select: none;
		-ms-user-select: none;
	}
	:global(.commandbar-animation) {
		background: linear-gradient(
			153.72deg,
			#0a196a 3.26%,
			#1535c9 73%,
			#316fe2 92.24%,
			#387fe1 95.76%
		) !important;
	}
	/* :global(body:before) {
		background: url('/bg/bg-pink-gradient.jpg') !important;
		mix-blend-mode: normal;
		background-size: cover;
	} */
</style>
