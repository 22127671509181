<script context="module">
	import TrackerBox from '../components/TrackerBox.svelte';
	import { getTimeSeries, uploadTimeSeries, postUserInfo } from '$lib/utils.js';
	import { Haptics } from '@capacitor/haptics';
	import dayjs from 'dayjs';
	let moodPromise = () =>
		getTimeSeries('ethi', { daysInPast: 0 }).catch((error) => console.error(error));
</script>

<script>
	import { UserInfo, Tracking, Data, DateRange } from '$lib/store.js';
	if (typeof window !== 'undefined') moodPromise();
	let metrics = [
		{
			name: 'happiness',
			antiName: 'sad',
			posName: 'happy',
			value: 5.0,
			color: '#00ff53',
			bg: 'rgba(0, 255, 102, 0.15)'
		},
		{
			name: 'energy',
			antiName: 'tired',
			posName: 'wired',
			value: 5.0,
			color: '#00D5FF',
			bg: 'rgba(0, 240, 255, 0.2)'
		},
		{
			name: 'calm',
			antiName: 'stressed',
			posName: 'calm',
			value: 5.0,
			color: '#FF0073',
			bg: 'rgba(255, 68, 152, 0.2)'
		},
		{
			name: 'focus',
			antiName: 'distracted',
			posName: 'focused',
			value: 5.0,
			color: '#1485ff',
			bg: 'rgb(57, 109, 255, 0.1)'
		}
	];
	let flatSortedEthi =
		$Data &&
		typeof $Data['["timeseries","time_series/ethi","noCache"]'] === 'object' &&
		Object.values($Data['["timeseries","time_series/ethi","noCache"]'])
			.flat()
			.filter((b) => b?.timeStamp)
			.sort(
				(a, b) =>
					dayjs(typeof b.timestamp == 'string' ? b.timestamp.replace('Z', '') : b.timestamp) -
					dayjs(typeof a.timestamp == 'string' ? a.timestamp.replace('Z', '') : a.timestamp)
			);
	let latestEthi = flatSortedEthi && flatSortedEthi?.[0] && flatSortedEthi?.[0];
	if (
		!$Tracking.mood.latest ||
		($Tracking.mood.latest &&
			latestEthi?.timeStamp &&
			dayjs($Tracking.mood.latest?.timeStamp) < dayjs(latestEthi?.timeStamp))
	)
		$Tracking.mood.latest = latestEthi;

	let todaysDate = $DateRange.end?.subtract(4, 'hour').format('YYYY-MM-DD');
</script>

<div
	class="w-full h-full flex flex-col justify-between items-center gap-6"
	style="max-height: 88vh;user-select: none;-webkit-user-select:none;moz-user-select:none"
>
	<div class="text-sm font-medium text-blue-100 opacity-90">TRACK YOUR MOOD</div>
	{#each metrics as metric}
		<TrackerBox
			bind:metric
			latestLog={$Tracking.mood.latest && $Tracking.mood.latest[metric.name]}
		/>
	{/each}
	<div class="relative flex justify-center" style="width: clamp(200px, 98%, 395px);">
		<div class="gradientShadow rounded-2xl" />
		<button
			class="p-4 bg-black rounded-2xl text-4xl font-bold"
			style="width: clamp(200px, 98%, 395px);"
			on:click={async () => {
				if (metrics.every((m) => m.value == 5)) {
					Haptics.notification({ type: 'ERROR' });
					$Tracking.mood.tracking = false;
					return;
				}
				$Tracking.mood.latest = {
					timeStamp: dayjs().format(),
					happiness: metrics?.[0].value,
					energy: metrics[1].value,
					calm: metrics[2].value,
					focus: metrics[3].value
				};
				$Tracking.mood.tracking = false;
				await moodPromise();
				if ($Data && typeof $Data['["timeseries","time_series/ethi","noCache"]'] === 'object')
					$Data['["timeseries","time_series/ethi","noCache"]'][todaysDate] = [
						...($Data['["timeseries","time_series/ethi","noCache"]'][todaysDate] || []),
						$Tracking.mood.latest
					].filter((a) => a);
				else
					$Data['["timeseries","time_series/ethi","noCache"]'] = Object.fromEntries([
						[todaysDate, [$Tracking.mood.latest]]
					]);
				Haptics.notification({ type: 'SUCCESS' });
				$UserInfo.moodsTracked = {
					timesTracked: Object.values($Data['["timeseries","time_series/ethi","noCache"]'] || {})
						.filter((b) => b?.timeStamp)
						.flat().length,
					daysTracked: Object.values(
						$Data['["timeseries","time_series/ethi","noCache"]'] || {}
					).filter((b) => b?.timeStamp).length,
					lastTracked: dayjs().format()
				};
				postUserInfo();
				uploadTimeSeries($Data['["timeseries","time_series/ethi","noCache"]'][todaysDate], 'ethi');
			}}
		>
			<span>{metrics.every((m) => m.value == 5) ? 'Skip' : 'Done'}</span>
		</button>
	</div>
</div>

<style>
	.gradientShadow {
		position: absolute;
		z-index: 0;
		pointer-events: none;
		top: -3px;
		left: 0;
		width: 100%;
		height: calc(6px + 100%);
		background: linear-gradient(120deg, #00ff53 0%, #00d5ff 35%, #1485ff 70%, #ff0073 100%);
		filter: blur(5px);
		content: '';
	}
	button span {
		background: linear-gradient(135deg, #00ff53 10%, #00d5ff 35%, #1485ff 65%, #ff0073 90%);
		background-clip: text;
		-webkit-background-clip: text;
		color: transparent;
	}
</style>
