<script>
	import Modal from '../components/Modal.svelte';
	import dayjs from 'dayjs';
	import AreaLineGraph from '../components/charts/chartFunctions/AreaLineGraph.svelte';
	import Activity from '../components/Activity.svelte';
	import TimeAxis from '../components/TimeAxis.svelte';
	import MoodTracker from '../components/MoodTracker.svelte';

	import Icon from '../components/Icon.svelte';
	import { calculateScores, makeDecimalIntoTime } from '$lib/connectUtils';
	import { insightAttributes } from '$lib/attributes.js';
	import { onMount } from 'svelte';
	import { Activities, Tracking, UserEmail, TodaysData } from '$lib/store.js';
	export let showTrackingButtons = true;
	let attributes = insightAttributes;
	let data, increase, todaysDate;
	$: color =
		(showActivity || increase) &&
		attributes &&
		(showActivity || increase).attributes &&
		attributes[(showActivity || increase).attributes?.[0]] &&
		attributes[(showActivity || increase).attributes?.[0]].color;
	let scores = calculateScores(11.5, dayjs().hour(8));
	let power = scores.projected_potential.slice(0, 20 * 60); //.reverse();
	let gradient = power[1].value - power?.[0].value;
	let powerData = Object.fromEntries(power.map((s) => [s.timestamp, s.value])); //.reverse();
	let sleepTime = dayjs()
		// .subtract(1, 'day')
		.startOf('minute')
		.hour(3);
	let sleep = Object.fromEntries(
		Array(4 * 60 + 1)
			.fill()
			.map((_, i, a) => [
				sleepTime.add(i, 'minute').format(),
				(power?.[0].value - gradient * (i / a?.length) * (a?.length - i)) *
					((i + a?.length / 2) / (a?.length * 1.5))
			])
	);
	$: category = [...Object.keys(sleep), ...Object.keys(powerData)].map((t) => dayjs(t)).reverse();

	data = [powerData, sleep];
	onMount(() => {
		let doubleData = [...(data || []), ...(data || [])];
	});

	let colorList = ['#64b0ff', '#7176ff'];
	let colors = [...(colorList || []), ...(colorList || [])];
	// let calendarFullScreen;
	const appendChild = (node) => {
		document.querySelector('#content').appendChild(node);
	};
	let summary, showActivity;
	// $: console.log($Tracking);
	let updateOnInterval = Math.random();
	setInterval(() => {
		updateOnInterval = Math.random();
	}, 1000 * 61);
</script>

<div id="calendarFullScreen" class="absolute top-0 left-0 w-full h-screen text-xs">
	<TimeAxis orientation={'vertical'} />
	{#key updateOnInterval}
		<div class="absolute h-full bg-blue-100 bg-opacity-10" style="top: 0;width: 1px;left:2.5rem;" />
		<div
			class="absolute left-10 h-px bg-blue-100 z-20"
			style="top: {0.85 +
				dayjs().diff(dayjs().subtract(4, 'hour').startOf('day').hour(4).startOf('hour'), 'second') /
					(25 * 36)}%;width: calc(100% - 40px);"
		/>
		<div
			class="absolute left-10 rounded-full h-[6px] w-[6px] bg-blue-100 z-20 -translate-x-1/2 -translate-y-1/2"
			style="top: {0.85 +
				dayjs().diff(dayjs().subtract(4, 'hour').startOf('day').hour(4).startOf('hour'), 'second') /
					(25 * 36)}%;"
		/>
		<div
			class="absolute right-1 text-gray-400 opacity-90 z-20"
			style="font-size:10px;top: {0.95 +
				dayjs().diff(dayjs().subtract(4, 'hour').startOf('day').hour(4).startOf('hour'), 'second') /
					(25 * 36)}%;"
		>
			{dayjs().format('HH:mm')}
		</div>
	{/key}
	<div class="absolute ml-10 top-0 mr-4 h-full left-0" style="width: calc(100% - 40px);">
		{#each $Activities as activity}
			<div
				class="absolute w-full z-10 flex my-1 hover:brightness-110 rounded justify-between cursor-pointer"
				style="top:{0.35 +
					dayjs(activity.startDate || activity.startedAt || activity.timestamp).diff(
						dayjs().subtract(4, 'hour').startOf('day').hour(4).startOf('hour'),
						'second'
					) /
						(25 * 36)}%;left:0;height:{activity.duration /
					(25 *
						36)}%;background:linear-gradient(153.72deg, #080F34 0%, #0A196A88 28.26%, #1535C9aa 73%, #114Ff298 99.76%), #223;box-shadow: inset 0 0 5px 0px {(attributes[
					activity?.attributes?.[0]
				]?.color || '#3256ff') + 66};"
				on:click={() => {
					summary = true;
					showActivity = activity;
				}}
			>
				{#if activity.duration > 1500}
					<div
						class="flex text-xs font-semibold justify-between w-full pl-4 pr-2 pt-px text-blue-100"
					>
						{#if activity.name === 'Work'}
							{#each Array.from(new Set(activity?.contexts
										?.sort((a, b) => b.duration - a.duration)
										?.map((c) => c?.categories?.[0]) || ['None']))
								.filter((c) => c !== 'Untracked')
								.slice(0, 2) as category, i}
								{#if i}
									{' and '}
								{/if}
								{category}
							{/each}
						{:else}
							<div class="flex ">{activity.name || 'Work session'}</div>
						{/if}
						<div class="opacity-70">
							{makeDecimalIntoTime(activity.duration / 3600, 'durationNoZero')}
						</div>
					</div>
				{/if}
			</div>
		{/each}
	</div>
	{#if showTrackingButtons}
		<div class="absolute bottom-0 left-0 p-8 justify-around w-full flex items-center">
			{#each ['mood', 0, 'other'] as attribute}
				{#if !attribute}
					<div class="w-12 h-12" />
				{:else}
					<button
						on:click={() => ($Tracking[attribute].tracking = true)}
						class="w-12 p-0 h-12 rounded-full flex items-center justify-center text-center text-sm font-semibold"
						style="background:{attributes[attribute].color};color:white"
					>
						<Icon
							{attribute}
							glowing={false}
							color={'white'}
							includeText={false}
							size={[1.8, 1.8]}
						/>
					</button>
				{/if}
			{/each}
		</div>
	{/if}
	{#if $Tracking.mood.tracking}
		<Modal
			absolute={true}
			bind:show={$Tracking.mood.tracking}
			on:close()={() => {
				$Tracking.mood.tracking = false;
			}}
			options={{ cssText: 'margin-bottom:4rem;z-index: 60' }}
		>
			<MoodTracker />
		</Modal>
	{/if}
	{#if $Tracking.other.tracking}
		<Modal
			absolute={true}
			bind:show={$Tracking.other.tracking}
			on:close()={() => {
				$Tracking.other.tracking = false;
			}}
			options={{ cssText: 'margin-bottom:4rem;z-index: 60' }}
		>
			<div class="text-center text-base">
				<p>We're exploring what else we should add tracking capability for.</p>
				<p>Let us know what you want!</p>
				<a
					class="block w-full relative cursor-pointer bg-transparent justify-end shadow-none p-1 m-1 ml-0"
					style="color:#1982FC;"
					on:click={(event) => {
						// if ($IsDev && $UserInfo.toursCompleted)
						// 	$UserInfo.toursCompleted = $UserInfo.toursCompleted.filter((a) => a !== $Path);
					}}
					href="sms:+447576358357?&body={encodeURI(
						`Hey Mike, it's ${
							($UserEmail || '')?.replace('@', ' @ ') || 'a new user'
						}, I have more things I want to track on Magicflow!`
					)}"
				>
					<button class="w-full">Tell us what you want to track</button>
				</a>
			</div>
		</Modal>
	{/if}
</div>

{#if showActivity}
	<Activity increase={showActivity} bind:summary {todaysDate} bind:showActivity />
{/if}

<style>
	/* #calendarFullScreen:before {
		content: ' ';
		display: block;
		position: absolute;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		opacity: 1;
		background-image: url(/bg/bg-blue-blur.webp);
		background-size: cover;
		background-position: center;
		filter: blur(5px)
	} */
</style>
