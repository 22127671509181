<script>
	// import Shepherd from 'shepherd.js';
	import { Onboarding, Path, UserInfo, IsDev, UserEmail, ShowDetailsFor } from '$lib/store.js';
	import autoAnimate from '@formkit/auto-animate';
	import { fly } from 'svelte/transition';
	import { Remarkable } from 'remarkable';
	import { onMount, onDestroy } from 'svelte';
	import { goto } from '$app/navigation';
	import { postUserInfo } from '$lib/utils.js';
	import AnimatedTick from './AnimatedTick.svelte';
	import Modal from './Modal.svelte';
	let tour;
	// $Onboarding = true;
	// $UserInfo.toursCompleted = [];
	let Shepherd;
	let activeTour;
	let bodyRect;
	let playing;

	const md = new Remarkable({
		highlight: function (str, lang) {
			if (lang && hljs.getLanguage(lang)) {
				try {
					return hljs.highlight(lang, str).value;
				} catch (err) {}
			}

			try {
				return hljs.highlightAuto(str).value;
			} catch (err) {}

			return ''; // use external default escaping
		}
	});

	onMount(async () => {
		fetchYoutube();
		// var monitor = setInterval(function () {
		// 	var elem = document.activeElement;
		// 	if (elem && elem.tagName == 'IFRAME') {
		// 		clearInterval(monitor);
		// 		playing = true;
		// 	}
		// }, 100);
		document?.querySelectorAll('#TourModal')?.forEach((m) => m?.remove());
		document?.querySelectorAll('#TourModalBg')?.forEach((m) => m?.remove());
		bodyRect = document?.body.getBoundingClientRect();

		if (window.Shepherd) {
			Shepherd = window.Shepherd;
			Shepherd.activeTour?.hide();
		} else {
			let obj = await import('shepherd.js');
			Shepherd = obj.default;
			window.Shepherd = Shepherd;
		}
		return () => {
			big = false;
			document?.querySelectorAll('#TourModal')?.forEach((m) => m?.remove());
			document?.querySelectorAll('#TourModalBg')?.forEach((m) => m?.remove());
		};
	});
	let player;
	let ready;
	$: console.log(player, ready);
	function onYouTubeIframeAPIReady() {
		console.log('onYouTubeIframeAPIReady');
	}
	async function setupPlayer() {
		while (!window.YT) {
			await new Promise((r) => setTimeout(r, 200));
		}
		player =
			player ||
			new YT.Player('player', {
				events: {
					onReady: onPlayerReady,
					onStateChange: onPlayerStateChange
				}
			});
	}
	async function playOrPause() {
		while (!window.YT) {
			await new Promise((r) => setTimeout(r, 200));
		}
		player =
			player ||
			new YT.Player('player', {
				events: {
					onReady: onPlayerReady,
					onStateChange: onPlayerStateChange
				}
			});
		let conditionFunction = () => player;
		async function waitFor() {
			return await new Promise((r) =>
				setInterval(async () => {
					console.log('waiting for ready', ready, player);
					if (conditionFunction()) r();
				}, 200)
			);
		}
		console.log('checking if ready');
		waitFor();

		console.log(player, player?.playVideo);
		if (playing) {
			playing = false;
			player.pauseVideo();
		} else {
			if (typeof player.playVideo === 'function') {
				playing = true;
				player.playVideo();
			} else {
				while (!typeof player.playVideo === 'function') {
					await new Promise((r) => setTimeout(r, 100));
				}
				player.playVideo();
				playing = true;
			}
		}
	}

	// 4. The API will call this function when the video player is ready.
	function onPlayerReady(event) {
		ready = true;
		//   event.target.playVideo();
		console.log('ready', event);
		ready = true;
	}

	// 5. The API calls this function when the player's state changes.
	//    The function indicates that when playing a video (state=1),
	//    the player should play for six seconds and then stop.

	function onPlayerStateChange(event) {
		console.log(event);
		if (event.data == YT.PlayerState.PAUSED) {
			playing = false;
		} else {
			playing = true;
		}
	}
	function stopVideo() {
		//   player.stopVideo();
	}
	function fetchYoutube() {
		window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
		// 2. This code loads the IFrame Player API code asynchronously.
		var tag = document.createElement('script');

		tag.src = 'https://www.youtube.com/iframe_api';
		var firstScriptTag = document.getElementsByTagName('script')[0];
		firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
		// 3. This function creates an <iframe> (and YouTube player)
		//    after the API code downloads.
	}
	// onDestroy();
	$: tours = {
		'/': {
			name: 'Overview',
			complete: $UserInfo?.toursCompleted?.includes('/'),
			started: false,
			content: [
				[
					'Welcome to Magicflow!',
					'Magicflow helps you track your productivity, and keep you productive.'
				],
				[
					'Your Current Session',
					"Here we show you how productive you've been today, and how you're doing overall.",
					'#startFocusSession'
				],
				[
					'Your Productivity Dashboard',
					"Here we show you how productive you've been today, and how you're doing overall.",
					'#topLevel'
				],
				[
					'Your Productivity Score',
					'Your score is calculated from:<ul class="ml-5 list-disc"><li>the % of time you spend working</li><li>the number of context-switches you do</li><li>how long your average work-session is</li>',
					'.row-span-2 .wrapper2'
				],
				[
					'Context switches',
					'We categorize the activities you do into categories like Coding, Marketing, and Communication. A context switch is when you switch between major categories of work, e.g. coding -> communicating.',
					'#topLevelcontextswitches'
				],
				[
					'Context switches and productivity',
					'Context switches are productivity-killers, as it takes time (on average 9.5 minutes) for your brain to build up context for the new task you are focusing on.',
					'#topLevelcontextswitches'
				],
				[
					'Deep Work',
					"Deep work is measured when you've spent more than 5 minutes working in one context.",
					'#topLeveldeepwork'
				],
				[
					'Get into flow with focus timer',
					'When you want to get in the zone, try starting a focus timer. Think of it as a Pomodoro timer with live stats and accountability!',
					'#startFocusSession button'
				],
				[
					'Diving deeper into metrics',
					'You can click on any of the metrics to see more details.',
					'#topLevel'
				]
				// [
				//     "You can also click on the '?' icon to see a quick tour.",
				// ],
			]
		},
		'/social': {
			name: 'Social',
			complete: $UserInfo?.toursCompleted?.includes('/social'),
			started: false,
			content: [
				[
					'Welcome to social Magicflow.',
					'Join or create groups to compete on a weekly leaderboard.',
					'#social'
				],
				['Get started!', 'Join an existing group, or start a new one here.', '#newGroup']
			]
		},
		'/insights': {
			name: 'Insights',
			complete: $UserInfo?.toursCompleted?.includes('/insights'),
			started: false,
			content: [
				[
					'Mining your data for insight',
					`In the background, we're constantly looking for insights into your data, connections that will help you learn how to improve.`,
					''
				],
				[
					'Insights take time',
					`If you've just signed up you won't see much here yet, because it takes a few weeks to collect enough data to give you meaningful insights.`,
					''
				],
				[
					'Connecting all your data',
					`If you connect your Apple Health data, you'll get even richer insights into how your sleep and exercise affect your productivity.`,
					''
				],
				[
					'Improving over the long-term',
					`Collecting all this data is the first step on a multi-year journey to a deep understanding of what makes you feel great and achieve great things!`,
					''
				]
			]
		}
	};
	let starting;
	let tourInProgress;
	let originalRect;
	async function startTour(path, element) {
		// if (!tours[path]) {
		// 	console.log('no tours for ', { path }, tours);
		// 	starting = false;
		// 	return;
		// }
		// if (tours[path] && tours[path].started) return;
		// tours[path].started = true;
		originalRect = element?.getBoundingClientRect();
		activeTour = tours[path];
		big = false;
		tourInProgress = path;
		goto(path);
		// console.log({ Shepherd });
		tour = new Shepherd.Tour({
			useModalOverlay: false,
			defaultStepOptions: {
				classes:
					'mx-1 font-medium text-sm shadow-md bg-gray-900 rounded-2xl p-4 w-11/12 max-w-[400px] centreInContext absolute border-2 border-white border-solid border-opacity-50 z-50'
				// scrollTo: true
			}
		});
		tours[path].content.forEach(([title, text, element], i, a) => {
			tour.addStep({
				id: element?.slice(1),
				title,
				text,
				attachTo: {
					element,
					on: 'auto'
				},
				canClickTarget: false,
				classes: '',
				buttons: [
					{
						text: i === a?.length - 1 ? 'Done' : 'Next',
						action: i === a?.length - 1 ? tour.complete : tour.next
					}
				]
			});
		});
		let previousTour = Shepherd.activeTour ? { ...(Shepherd.activeTour || []) } : false;
		let previousStep;
		if (previousTour) {
			previousStep = previousTour.getCurrentStep();
			console.log({ previousStep });
			previousTour.hide();
		}
		tour.on('complete', () => {
			console.log('tour complete for: ', path);
			tours[path].complete = true;
			tours[path].started = false;
			tourInProgress = false;
			$UserInfo.toursCompleted = [...($UserInfo.toursCompleted || []), path];
			postUserInfo();
			if (previousTour) {
				previousTour.show(previousStep.id);
			}
		});
		tour.start();
	}
	// $: if (
	// 	Shepherd &&
	// 	$UserInfo.onboardingComplete &&
	// 	tours[$Path] &&
	// 	!tours[$Path].complete &&
	// 	!tours[$Path].started
	// )
	// 	setTimeout(
	// 		() =>
	// 			tours[$Path] && (!tours[$Path].complete && !tours[$Path].started ? startTour($Path) : ''),
	// 		500
	// 	);
	// $: if (
	// 	Shepherd &&
	// 	$UserInfo.onboardingComplete &&
	// 	tours['time' + $ShowDetailsFor.time] &&
	// 	!tours['time' + $ShowDetailsFor.time].complete &&
	// 	!tours['time' + $ShowDetailsFor.time].started
	// )
	// 	setTimeout(
	// 		() =>
	// 			tours['time' + $ShowDetailsFor.time] &&
	// 			!tours['time' + $ShowDetailsFor.time].complete &&
	// 			!tours['time' + $ShowDetailsFor.time].started
	// 				? startTour('time' + $ShowDetailsFor.time)
	// 				: '',
	// 		500
	// 	);

	/* 
   


	view.webContents.mainFrame.frames.forEach(frame => {
  const url = new URL(frame.url)
  if (url.host === 'sub.whatever.com') {
    frame.executeJavaScript('myCodeHere')
  }
})
    */
	$: toursLength = Object.entries(tours).length;
	let big = !$IsDev && $UserEmail && $Onboarding;
	let fullMenu = big;
	let menu, menuLength, menuInterval;
	// $: console.log(menu);
	let playerTime = 0;
	setInterval(() => {
		playerTime = player?.playerInfo?.currentTime || 0;
	}, 500);
	// $: console.log({ big });
	function latencyTest(videoElement) {
		let startTime = 0;

		videoElement.addEventListener('loadstart', () => {
			startTime = performance.now();
			console.log('Video loading started');
		});

		videoElement.addEventListener('loadeddata', () => {
			const endTime = performance.now();
			const latency = endTime - startTime;
			console.log(`Video's first frame is rendered. Latency: ${latency} ms`);
		});
	}
	let showUnderstanding, showUse, showDefinitions;
</script>

<svelte:window
	on:keydown={(e) => {
		if (
			(Shepherd?.activeTour || (tourInProgress && tours[tourInProgress]?.started)) &&
			e.key === 'Escape'
		) {
			Shepherd.activeTour.hide();
			document.querySelectorAll('.shepherd-modal-overlay-container')?.forEach((c) => c.remove());
			tours[tourInProgress].complete = true;
			$UserInfo.toursCompleted = [...($UserInfo.toursCompleted || []), tourInProgress];
			postUserInfo();
			tourInProgress = false;
		}
	}}
/>
{#if $Onboarding}
	<Modal
		bind:show={$Onboarding}
		options={{
			name: 'Tutorial',
			cssText: `width:90%;padding:2rem;background:#0a111a;border: 2px #eef1 solid;max-width:100%;height:90%;max-height:100%;`
		}}
		allowClose={!showUnderstanding && !showUse && !showDefinitions}
	>
		<div
			class="flex gap-8 my-4 w-full h-full items-center transition-opacity {!showUnderstanding &&
			!showUse &&
			!showDefinitions
				? 'opacity-100'
				: 'opacity-0'}"
		>
			<div class="grid gap-4 w-[35%] text-lg flex-shrink-0 flex-grow">
				{@html md.render(`## **Welcome! Let’s get you up to speed.**`)}
				<div>
					You probably just started using Magicflow, so you won’t have much data yet. Don’t worry,
					Magicflow has started tracking your activity!
				</div>
				On the right you can see what the app will look like with data.
				<div>
					Click the buttons below to find out what the parts of the UI mean, and how Magicflow
					works.
				</div>
				<div class="grid gap-4 mt-4 text-base">
					<button class="font-semibold" on:click={() => (showUnderstanding = !showUnderstanding)}>
						Understanding the UI
					</button>

					<button class="font-semibold" on:click={() => (showUse = !showUse)}
						>How to use the app</button
					>

					<button class="font-semibold" on:click={() => (showDefinitions = !showDefinitions)}>
						Deep work, context switches & productivity
					</button>
				</div>
			</div>
			<img src="/app_dark.png" class="rounded-md w-[60%] ml-auto" alt="app screenshot" />
		</div>
		<Modal
			absolute={true}
			options={{ cssText: `max-width:100%;width:100%;height:100%;padding:3rem 2rem 0;` }}
			bind:show={showUse}
			allowClose={false}
		>
			<button class="absolute top-6 left-[10%]" on:click={() => (showUse = false)}>Back</button>
			<iframe
				class="mt-4 w-full h-full reinvert"
				src={'/notionUse.html'}
				title="Understanding the UI"
			/>
			<div class="h-6" />
		</Modal>
		<Modal
			absolute={true}
			options={{ cssText: `max-width:100%;width:100%;height:100%;padding:3rem 2rem 0;` }}
			bind:show={showDefinitions}
			allowClose={false}
		>
			<button class="absolute top-6 left-[10%]" on:click={() => (showDefinitions = false)}
				>Back</button
			>
			<iframe
				class="mt-4 w-full h-full reinvert"
				src={'/notionDefinitions.html'}
				title="Understanding the UI"
			/>
			<div class="h-6" />
		</Modal>
		<Modal
			absolute={true}
			options={{ cssText: `max-width:100%;width:100%;height:100%;padding:3rem 2rem 0;` }}
			bind:show={showUnderstanding}
			allowClose={false}
		>
			<button class="absolute top-6 left-[10%]" on:click={() => (showUnderstanding = false)}
				>Back</button
			>

			<iframe
				class="mt-4 w-full h-full reinvert"
				src={'/notion.html'}
				title="Understanding the UI"
			/>
		</Modal>
	</Modal>
{/if}
{#if false}
	<Modal bind:show={big} options={{ name: 'Tour', cssText: `background:transparent;shadow:none;` }}>
		<div
			transition:fly={{ delay: 0, duration: 100, y: 100 }}
			use:autoAnimate
			bind:this={menu}
			class=" z-50 rounded-xl text-sm"
		>
			<!-- <button
				class="font-semibold absolute p-0 bg-transparent shadow-none top-5 right-6 z-10 cursor-pointer"
				on:click={() => (big = false)}
			>
				<svg
					xmlns="http://www.w3.org/2000/svg"
					class={'h-5 w-5'}
					fill="none"
					viewBox="0 0 24 24"
					stroke="currentColor"
				>
					<path
						stroke-linecap="round"
						stroke-linejoin="round"
						stroke-width="2"
						d="M6 18L18 6M6 6l12 12"
					/>
				</svg>
			</button> 
		-->
			{#if !activeTour}
				<div
					transition:fly={{
						delay: 100,
						duration: 600
						// y: (bodyRect?.height - 240) / 2 - originalRect?.y || -200,
						// x: originalRect?.x - (bodyRect?.width - 384) / 2 || -200
					}}
					on:click={() => {
						big = !false;
						activeTour = false;
					}}
					style="aspect-ratio: 1.5 / 1;background:#112233"
					class=" max-w-full bg-opacity-10 rounded-xl w-full flex flex-col items-center overflow-hidden"
				>
					<div class="relative w-full">
						<!-- <video
							use:latencyTest
							controls
							style="max-width:100%;width:100%;aspect-ratio: 1.6;border-radius:0.75rem 0.75rem 0 0;transform:scale(1.2);"
						>
							<source
								src="https://storage.googleapis.com/ethi_app/dist/cdn/channel-1-display-0.mp4"
								type="video/mp4"
							/>
							Your browser does not support the video tag.
						</video> -->

						<iframe
							use:setupPlayer
							id="player"
							style="max-width:100%;width:100%;aspect-ratio: 1.6;border-radius:0.75rem 0.75rem 0 0;transform:scale(1.2);"
							src="https://www.youtube.com/embed/Zf-tQSBUbU4"
							title="YouTube video player"
							frameborder="0"
							allow="accelerometer;encrypted-media; gyroscope; picture-in-picture;fullscreen"
							allowfullscreen
						/>
						{#if !playing}
							<div
								on:click={playOrPause}
								class="{ready
									? ''
									: 'bg-gray-500 bg-opacity-60'} absolute w-full h-full top-0 left-0"
								style={ready ? '' : 'filter:grayscale(0.6);'}
							>
								<div
									class=" centreInContext absolute bg-blue-500 rounded-full h-20 w-20 p-0 pointer-events-none"
								/>

								<svg
									xmlns="http://www.w3.org/2000/svg"
									viewBox="0 0 24 24"
									fill="currentColor"
									class=" w-36 h-36 centreInContext absolute  pointer-events-none"
								>
									<path
										fill-rule="evenodd"
										d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z"
										clip-rule="evenodd"
									/>
								</svg>
							</div>
						{/if}
					</div>

					<button
						style="border-radius:0 0 0.75rem 0.75rem ;"
						class="reinvert w-full {!tour?.complete
							? 'bg-gray-900 hover:bg-gray-900'
							: 'bg-blue-500 hover:bg-blue-700'}  flex gap-4 items-center capitalize text-white font-bold py-2 px-4 "
					>
						{#if tour?.complete}
							<AnimatedTick />
							{'Done'}
						{:else}
							<div class="rounded-full border-2 border-white w-4 h-4" />
							<div
								style="border-radius:0 0.7rem 0.7rem 0.7rem;transition-property: width;transition-timing-function: linear;
								transition-duration: 1250ms;width:{(100 * playerTime) / player?.playerInfo.duration}%;"
								class="absolute left-0 top-0 bg-blue-500 h-full"
							/>
							Watch now
						{/if}
					</button>
				</div>
			{/if}
			{#if big}
				<div
					style="grid-template-columns: repeat(auto-fit, minMax(200px, 1fr))"
					class="grid p-6 items-stretch gap-4 {!big ? 'pointer-events-none' : ''} "
				>
					{#each Object.entries(tours) as [path, tour], i (path)}
						<div
							transition:fly={{ delay: i * 100, duration: 400, y: 20 }}
							on:click={(e) => startTour(path, e.currentTarget)}
							class="bg-white bg-opacity-10 rounded-xl w-full flex flex-col items-center"
						>
							<div class="relative w-full">
								<img
									src="/tutorial/MagicflowHome.webp"
									alt="Magicflow home"
									style="max-width:100%;aspect-ratio: 1.6;border-radius:0.75rem 0.75rem 0 0;"
								/>
								<div class="centreInContext absolute bg-blue-500 rounded-full h-10 w-10 p-0" />
								<button class="centreInContext absolute bg-transparent p-0">
									<svg
										xmlns="http://www.w3.org/2000/svg"
										viewBox="0 0 24 24"
										fill="currentColor"
										class="w-16 h-16"
									>
										<path
											fill-rule="evenodd"
											d="M2.25 12c0-5.385 4.365-9.75 9.75-9.75s9.75 4.365 9.75 9.75-4.365 9.75-9.75 9.75S2.25 17.385 2.25 12zm14.024-.983a1.125 1.125 0 010 1.966l-5.603 3.113A1.125 1.125 0 019 15.113V8.887c0-.857.921-1.4 1.671-.983l5.603 3.113z"
											clip-rule="evenodd"
										/>
									</svg>
								</button>
							</div>

							<button
								style="border-radius:0 0 0.75rem 0.75rem ;"
								class="reinvert w-full {!tour.complete
									? 'bg-gray-900 hover:bg-gray-900'
									: 'bg-blue-500 hover:bg-blue-700'}  flex gap-4 items-center capitalize text-white font-bold py-2 px-4 rounded"
							>
								{#if tour.complete}
									<AnimatedTick />
								{:else}
									<div class="rounded-full border-2 border-white w-4 h-4 animate-pulse" />
								{/if}
								{path.replace('/', '') || 'Overview'}
							</button>
						</div>
					{/each}
				</div>
			{/if}
		</div>
	</Modal>
{/if}
<div class="hidden sm:block fixed bottom-6 right-4 z-10">
	<button
		class="font-semibold gradient-med transition-opacity mt-4 opacity-70 hover:opacity-100 text-xs block ml-auto px-2 py-0.5"
		on:click={() => {
			big = !big;
			$Onboarding = !$Onboarding;
		}}
	>
		{#if !$Onboarding}
			See onboarding
			<!-- : {Math.round(
				(100 * Object.values(tours).filter((t) => t.complete).length) /
					Object.values(tours).length
			)}% -->
			<!-- <span class="text-xs">
					({Object.values(tours).filter((t) => t.complete).length}/{Object.values(tours).length})
				</span> -->
		{:else}
			Hide onboarding
		{/if}
		<!-- <span
				on:click|stopPropagation={() => ($Onboarding = false)}
				class="bg-gray-900 rounded-full text-xs inline-flex flex-col items-center justify-center"
				>x</span
			> -->
	</button>
</div>

{#if activeTour && big}
	<div
		transition:fly={{
			delay: 100,
			duration: 600,
			y: (bodyRect?.height - 240) / 2 - originalRect?.y || -200,
			x: originalRect?.x - (bodyRect?.width - 384) / 2 || -200
		}}
		on:click={() => {
			big = !false;
			activeTour = false;
		}}
		class="fixed centreInContext top-3/4 max-w-sm bg-opacity-10 rounded-xl w-full flex flex-col items-center"
	>
		<iframe
			style="max-width:100%;width:100%;aspect-ratio: 1.6;border-radius:0.75rem 0.75rem 0 0;"
			src="https://www.youtube.com/embed/_9dTD5qh_8Y?modestbranding=1&controls=0&autoplay=1"
			title="YouTube video player"
			frameborder="0"
			allow="accelerometer;encrypted-media; gyroscope; picture-in-picture;fullscreen"
			allowfullscreen
		/>

		<button
			style="border-radius:0 0 0.75rem 0.75rem ;"
			class="reinvert w-full {!tour.complete
				? 'bg-gray-900 hover:bg-gray-900'
				: 'bg-blue-500 hover:bg-blue-700'}  flex gap-4 items-center capitalize text-white font-bold py-2 px-4 rounded"
		>
			{#if tour.complete}
				<AnimatedTick />
			{:else}
				<div class="rounded-full border-2 border-white w-4 h-4 animate-pulse" />
			{/if}
			{'Done'}
		</button>
	</div>
{/if}

<style>
	@media (prefers-color-scheme: dark) {
		iframe.reinvert {
			filter: invert(1) hue-rotate(-180deg) brightness(1.1);
		}
	}
</style>
